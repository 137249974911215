import React from 'react';
import { Typography, Tooltip } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { PrinterStatus } from 'Interfaces';
import simplifyPrinterStatus from 'Utils/simplifyPrinterStatus';
import readablePrinterStatus from 'Utils/readablePrinterStatus';

type Props = {
  status: PrinterStatus;
  isStopped: boolean;
  useIcons: boolean;
};
// Shows printer state
export default function PrinterState({ status, isStopped, useIcons }: Props) {
  const simpleStatus = simplifyPrinterStatus(status, isStopped);
  const htmlColor = simpleStatus === 'ready' ? 'green' : simpleStatus === 'processing' ? 'yellow' : 'red';
  const text = readablePrinterStatus(status, isStopped);
  return (
    <>
      {useIcons ? (
        <Tooltip title={<Typography variant="body2">{text}</Typography>}>
          <FiberManualRecordIcon htmlColor={htmlColor} />
        </Tooltip>
      ) : (
        <Typography variant="body2" color="inherit" style={{ color: htmlColor }}>
          {text}
        </Typography>
      )}
    </>
  );
}
