import { Box } from '@mui/material';
import { GoogleLogin } from '@react-oauth/google';
import { OauthPayload } from 'Components/RegistrationForm';
import React from 'react';
import vk from 'Assets/vk.svg';

type Props = { setPayload: React.Dispatch<React.SetStateAction<OauthPayload | undefined>> };
export default function OauthButtons(props: Props) {
  const { setPayload } = props;

  React.useEffect(() => {
    const authListener = (event: MessageEvent<any>) => {
      const { data } = event;
      if (data.type === 'token') {
        if (data.source === 'vk' || data.source === 'yandex') setPayload({ type: data.source, code: data.code });
      }
    };

    window.addEventListener('message', authListener);
    return () => {
      window.removeEventListener('message', authListener);
    };
  }, []);

  // Yandex auth
  const frameStyle = {
    border: 0,
  };

  // Vk auth
  const params = new URLSearchParams({
    client_id: '51622810',
    redirect_uri: 'https://printmipt.ru/page/vkAuthPage',
    response_type: 'code',
  });
  const uri = 'https://oauth.vk.com/authorize?' + params.toString();
  return (
    <Box display="flex">
      <Box maxHeight="60px" overflow="hidden" paddingTop="6px">
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            setPayload({ type: 'google', credentials: credentialResponse });
          }}
          onError={() => {
            console.log('Login Failed');
          }}
          type="icon"
          shape="square"
        />
      </Box>

      <Box maxHeight="60px" overflow="hidden">
        <iframe
          src="https://printmipt.ru/page/yandexAuthPage.html"
          title="yandex auth"
          height="60px"
          width="80px"
          style={frameStyle}
        ></iframe>
      </Box>
      <Box paddingTop="7px">
        <a href="#" onClick={() => window.open(uri, 'mywindow', 'width=1100,height=500')}>
          <img src={vk} height="38px" alt="Logo is missing!" />
        </a>
      </Box>
    </Box>
  );
}
