import { TextField, Button, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import UserContext from 'Context/UserContext/UserContext';
import React from 'react';

const useStyles = makeStyles()((theme) => ({
  textfield: {
    marginTop: theme.spacing(2),
    maxWidth: '100px',
  },
  submitButton: {
    margin: `${theme.spacing(2)} ${theme.spacing(2)}`,
  },
}));

export default function SimplePaymentForm() {
  const { classes } = useStyles();

  const { _id } = React.useContext(UserContext);

  const [sum, setSum] = React.useState<string>('100');
  const [valid, setValid] = React.useState<boolean>(true);

  const handleChange = (event) => {
    const value = parseFloat(event.target.value);
    if (!value || value <= 0) setValid(false);
    else setValid(true);
    setSum(event.target.value);
  };

  const handleSubmit = (event) => {
    if (!valid) event.preventDefault();
  };

  return (
    <form
      method="POST"
      action="https://printmipt.server.paykeeper.ru/create/"
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <TextField
        type="text"
        size="small"
        error={!valid}
        helperText={valid ? null : 'Положительное число'}
        name="sum"
        value={sum}
        onChange={handleChange}
        variant="outlined"
        className={classes.textfield}
        label={
          <Typography color="textPrimary" variant="body2">
            Сумма
          </Typography>
        }
      />
      <input type="text" name="clientid" value={_id} hidden />

      <Button type="submit" variant="contained" className={classes.submitButton} color="primary">
        <Typography variant="body1">Пополнить счет</Typography>
      </Button>
    </form>
  );
}
