import React from 'react';
import { PrinterData } from 'Interfaces';

export type SocketContextType = {
  printers: PrinterData[];
};

/** Default value to initialize user context in app.tsx */
export const socketContext: SocketContextType = {
  printers: [],
};

/** Context initializator */
export const SocketContext = React.createContext({
  ...socketContext,
  updateContext: (newData: Partial<SocketContextType>) => {},
});

export default SocketContext;
