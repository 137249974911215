/**
 * Button to save print settings
 * @packageDocumentation
 */
import React from 'react';
import { Typography, Button, Tooltip } from '@mui/material';
import axios from 'axios';

import { accessCookie } from 'Utils';
import { UserContext } from 'Context';
import { AlertState } from 'Components';
import { useNavigate } from 'react-router-dom';
import { isArrayFileData, PrintSettings } from 'Interfaces';

type Props = {
  setAlertState: (newState: AlertState) => void;
  isValidPrinter: boolean;
};

export default function SaveSettingsButton({ setAlertState, isValidPrinter }: Props) {
  const activeFile = React.useContext(UserContext).activeFile!;
  const { updateContext } = React.useContext(UserContext);

  const navigate = useNavigate();
  // Save print settings
  const saveSettings = async () => {
    const token = accessCookie('token');

    try {
      if (!activeFile.print_settings.printer_id) {
        setAlertState({ text: 'Принтер не выбран!', severity: 'warning', isOpened: true });
        return;
      }
      if (!isValidPrinter) {
        setAlertState({ text: 'Проверьте введенные данные!', severity: 'warning', isOpened: true });
        return;
      }
      const fileSettings: FileSettings = {
        system_name: activeFile.system_name,
        print_settings: activeFile.print_settings,
      };
      const response = await axios.post(
        '/api/print/updateSettings',
        { ...fileSettings },
        {
          headers: { authorization: 'Bearer ' + token },
        },
      );
      const files = response.data;
      // Type guard
      if (!isArrayFileData(files)) {
        console.log('Type guard error. Should be FileData[]');
        return;
      }
      setAlertState({ text: 'Настройки сохранены', severity: 'success', isOpened: true });
      setTimeout(() => {
        updateContext({ files: files, activeFile: null });
        navigate('/');
      }, 1000);
    } catch (error: any) {
      alert(error.response?.data);
    }
  };

  return (
    <Tooltip title={<Typography variant="body1">Сохранить настройки печати</Typography>}>
      <Button
        variant="outlined"
        color="secondary"
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => saveSettings()}
      >
        Сохранить
      </Button>
    </Tooltip>
  );
}

// Sends updated print settings to the user
type FileSettings = {
  /** unique file name */
  system_name: string;
  /** print settings */
  print_settings: PrintSettings;
};
