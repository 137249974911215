import React from 'react';
import { Tooltip, Typography } from '@mui/material';

// Display paper in the printers tray
export default function PausePresentationRounded({ paper, useIcons }: { paper: number; useIcons: boolean }) {
  return (
    <>
      {useIcons ? (
        <Tooltip title={<Typography variant="body2">Бумага в принтере</Typography>}>
          <Typography variant="body2" color="inherit">
            {paper}
          </Typography>
        </Tooltip>
      ) : (
        <>{paper} стр.</>
      )}
    </>
  );
}
