/**
 * One row of the printers menu.
 * @packageDocumentation
 */
import React from 'react';
import { MenuItem, ListItemIcon, ListItemText } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { PrinterData } from 'Interfaces';
import { PrinterState, ColorMode, PaperState } from 'Components';

const useItemStyles = makeStyles()((theme) => ({
  root: {
    minWidth: '200px',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  favourite: {
    backgroundColor: theme.palette.primary.light,
  },
  common: {},
  listNoIcon: { marginLeft: theme.spacing(1) },
}));

type Props = {
  printer: PrinterData;
  favourite: boolean;
  disabled: boolean;
  changePrinter: (printer: PrinterData) => void;
  useIcons: boolean;
};
// Menu items
export default function PrintersMenuItem(props: Props) {
  const { printer, favourite, disabled, changePrinter, useIcons } = props;
  const { classes } = useItemStyles();

  return (
    <MenuItem
      disabled={disabled}
      className={favourite ? classes.favourite : classes.common}
      classes={{ root: classes.root }}
      onClick={() => changePrinter(printer)}
    >
      {useIcons ? (
        <>
          <ListItemIcon>
            <PrinterState status={printer.status} isStopped={printer.isStopped} useIcons={useIcons} />
          </ListItemIcon>
          <ListItemIcon>
            <ColorMode color={printer?.print_capabilities.color} useIcons={useIcons} />
          </ListItemIcon>
          <ListItemIcon>
            <PaperState useIcons={useIcons} paper={printer.paper} />
          </ListItemIcon>
          <ListItemText>{printer.name}</ListItemText>
        </>
      ) : (
        <>
          <ListItemText className={classes.listNoIcon}>{printer.name}</ListItemText>
          <ListItemIcon className={classes.listNoIcon}>
            <ColorMode color={printer?.print_capabilities.color} useIcons={useIcons} />
          </ListItemIcon>
          <ListItemIcon className={classes.listNoIcon}>
            <PaperState useIcons={useIcons} paper={printer.paper} />
          </ListItemIcon>
          <ListItemIcon className={classes.listNoIcon}>
            <PrinterState status={printer.status} isStopped={printer.isStopped} useIcons={useIcons} />
          </ListItemIcon>
        </>
      )}
    </MenuItem>
  );
}
