/**
 * This file contains async loader and some stylings for the preview document component.
 * @packageDocumentation
 */
import React from 'react';
import axios, { ResponseType } from 'axios';
import { Grid, Box, Typography, LinearProgress } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import PreviewDocument from './Preview/PreviewDocument';
import { accessCookie } from 'Utils';
import { FileData } from 'Interfaces';

const useStyles = makeStyles()((theme) => ({
  header: {
    marginLeft: theme.spacing(5),
    marginTop: theme.spacing(5),
  },
  progress: {
    width: theme.spacing(25),
    marginLeft: theme.spacing(2.5),
  },
  progressLabel: {
    marginLeft: theme.spacing(0.5),
  },
}));

export default function Preview({ activeFile }: { activeFile: FileData }) {
  const [progress, setProgress] = React.useState<number>(0);
  const [previewUrl, setPreviewUrl] = React.useState<string | null>(null);

  const { classes } = useStyles();

  const system_name = activeFile?.system_name;

  // Async loader. Runs only once.
  React.useEffect(() => {
    const load = async () => {
      const token = accessCookie('token');
      // Trying to fetch file data
      try {
        const config = {
          headers: { authorization: 'Bearer ' + token },
          responseType: 'blob' as ResponseType,
          onDownloadProgress: function (progressEvent: ProgressEvent) {
            const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setProgress(percent);
            if (percent === 100) {
              setProgress(0);
            }
          },
        };

        const data = { fileName: system_name };
        const response = await axios.post('/api/file/preview', data, config);
        const file = new Blob([response.data], { type: 'application/pdf' });
        const fileURL = URL.createObjectURL(file);
        setPreviewUrl(fileURL);
      } catch (error) {
        console.log(error);
      }
    };
    load();
  }, [system_name]);
  return (
    <Grid container>
      {progress !== 0 && (
        <Box
          width="100%"
          justifyContent="start"
          display="flex"
          flexDirection="row"
          alignItems="center"
          className={classes.header}
        >
          <Typography variant="body1" color="textPrimary">
            Загрузка
          </Typography>
          <LinearProgress className={classes.progress} variant="determinate" color="secondary" value={progress} />
          <Typography className={classes.progressLabel} variant="body2" color="textPrimary">{`${Math.round(
            progress,
          )}%`}</Typography>
        </Box>
      )}
      {previewUrl && <PreviewDocument file={previewUrl} />}
    </Grid>
  );
}
