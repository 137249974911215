/**
 * One row of the printers menu.
 * @packageDocumentation
 */
import React from 'react';
import { MenuItem, ListItemText } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

const useItemStyles = makeStyles()((theme) => ({
  root: {
    minWidth: '200px',
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  common: {},
  listItem: { marginLeft: theme.spacing(1) },
}));

type Props = {
  printer_name: string;
  printer_id: string;
  setDefaultPrinter: (printer_id: string) => void;
};
// Menu items
export default function DefaultPrinterMenuItem(props: Props) {
  const { printer_name, setDefaultPrinter, printer_id } = props;
  const { classes } = useItemStyles();

  return (
    <MenuItem className={classes.common} classes={{ root: classes.root }} onClick={() => setDefaultPrinter(printer_id)}>
      <>
        <ListItemText className={classes.listItem}>{printer_name}</ListItemText>
      </>
    </MenuItem>
  );
}
