/**
 * This file contains navigation bar on the Landing page
 * @packageDocumentation
 */
import React from 'react';
import { AppBar, Tabs, Tab, Grid, Button, Hidden } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Link } from 'react-router-dom';

import logo from 'Assets/cloud(white).svg';

const useStyles = makeStyles()((theme) => ({
  navbar: {
    height: theme.spacing(8),
    alignItems: 'center',
    flexDirection: 'row',
    width: '100%',
  },
  logo: {
    height: theme.spacing(7),
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    paddingTop: theme.spacing(0.5),
  },
  link: {
    padding: `0px ${theme.spacing(2.5)}`,
    textTransform: 'none',
  },
  buttonGroup: {
    marginLeft: 'auto',
  },
  button: {
    margin: `0px ${theme.spacing(2)}`,
  },
}));

/**
 * This is the navigation bar for the landing page. It contains logo and two buttons: Reqister and Login.
 */
export default function TopBar() {
  const { classes, cx } = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => setValue(newValue);

  return (
    <AppBar className={classes.navbar} position="static">
      <Link to="/landing" onClick={(event) => handleChange(event, 0)}>
        <img src={logo} className={classes.logo} alt="Logo is missing!" />
      </Link>
      <Tabs value={value} onChange={handleChange}>
        <Tab component={Link} className={classes.link} label="О нас" to="/landing" />
      </Tabs>
      <Grid item className={cx(classes.buttonGroup)}>
        <Button href="/login" className={classes.button} color="inherit" variant="outlined">
          Вход
        </Button>
      </Grid>
    </AppBar>
  );
}
