// Parses date to display in table cell
export default function parsed_date(value: Date) {
  const date = new Date(value);
  const currentDate = new Date();
  // For current date return only time.
  if (
    date.getFullYear() === currentDate.getFullYear() &&
    date.getMonth() === currentDate.getMonth() &&
    date.getDate() === currentDate.getDate()
  ) {
    const minutes = date.getMinutes();
    return date.getHours() + ':' + (minutes > 9 ? minutes : '0' + minutes);
  }
  return date.getDate() + ' ' + MonthNames[date.getMonth()];
}

const MonthNames = [
  'января',
  'февраля',
  'марта',
  'апреля',
  'мая',
  'июня',
  'июля',
  'августа',
  'сентября',
  'октября',
  'ноября',
  'декабря',
];
