/**
 * Form for file uploads. Renders on top of print page.
 * @packageDocumentation
 */
import React from 'react';
import axios from 'axios';
import { Paper, Box, Typography, Button, Input, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { UserContext } from 'Context';
import { FileData } from 'Interfaces';
import { accessCookie } from 'Utils';
import { Alert, useAlert } from 'Components';
import useUploadProgress from './FileUploadForm/useUploadProgress';
import UploadProgress from './FileUploadForm/UploadProgress';

type Props = {
  file: string; // States from the parent component
  setFile(v: string): void; // Sets file name
  setActiveFile(file: FileData | null): void; // Is set on file processing end
};
/** File upload form */
export default function FileUploadForm({ file, setFile, setActiveFile }: Props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { uploadState, progress, setUploadState } = useUploadProgress();

  const { updateContext, defaultPrinter } = React.useContext(UserContext);

  // Alert message states
  const { alertState, handleAlertClose, setAlertState } = useAlert();

  const submit = async (event: React.FormEvent<HTMLFormElement>) => {
    try {
      const input = event.target as HTMLInputElement;
      if (input.files === null) {
        throw new Error('No files submitted!');
      }
      const file: File = input.files[0];
      const token = accessCookie('token');
      const data = new FormData();
      data.append('file', file);

      setFile(file.name);
      setUploadState({ state: 'loading', progress: 0 });
      // Refreshes form to enable uploading the same file several times
      input.value = '';
      /** Sending http request with authorization token and files. */
      const config = {
        headers: { authorization: 'Bearer ' + token },
        onUploadProgress: function (progressEvent: ProgressEvent) {
          const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadState({ progress: percent });
          if (percent === 100) {
            setUploadState({ state: 'processing', progress: 100 });
          }
        },
      };

      const response = await axios.post('/api/file/upload', data, config);
      const { files, newFile } = response.data;
      newFile.print_settings.printer_id = defaultPrinter;
      setActiveFile(newFile);
      setUploadState({ state: 'finished', progress: 100 });
      updateContext({ files });
      navigate('/print');

      // File successfully converted to pdf and is ready for printing.
    } catch (error: any) {
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        setAlertState({ text: error.response.data, severity: 'warning', isOpened: true });
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }

      setFile('');
      setUploadState({ state: 'none', progress: 0 });
    }
  };
  return (
    <Paper elevation={6}>
      <Box display="flex" alignItems="center" height={theme.spacing(8)} maxWidth="100vw" overflow="auto">
        <Box marginLeft={theme.spacing(2.5)}>
          <form encType="multipart/form-data" onChange={(event) => submit(event)}>
            <Button variant="contained" component="label" htmlFor="LoadFile" color="primary">
              <Typography variant="body1">Загрузить файл</Typography>
            </Button>
            <Input type="file" id="LoadFile" inputProps={{ accept: supportedFormats }} style={{ display: 'none' }} />
          </form>
        </Box>

        <Box marginLeft={theme.spacing(2.5)}>
          <Typography variant="body2">{file}</Typography>
        </Box>
        <Box marginLeft={theme.spacing(2.5)}>
          <UploadProgress state={uploadState} progress={progress} />
        </Box>
      </Box>
      <Alert state={alertState} autoHideDuration={6000} onClose={handleAlertClose} />
    </Paper>
  );
}

const supportedFormats = [
  '.pdf',
  // Text
  '.txt',
  '.rtf',
  '.csv',
  '.css',
  '.html',
  // Images
  '.png',
  '.bmp',
  '.jpg',
  '.jpeg',
  '.tif',
  '.tiff',
  // Office
  '.odp',
  '.odt',
  '.ods',
  '.doc',
  '.docx',
  '.xls',
  '.xlsx',
  '.ppt',
  '.pptx',
];
