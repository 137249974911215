/**
 * Navigation bar for every page.
 * @packageDocumentation
 */

import { AppBar, Box, Grid, Tab, Tabs, Typography, useTheme } from '@mui/material';
import React, { useContext } from 'react';

import { Link } from 'react-router-dom';
import Menu from './NavigationBar/NavigationBarMenu';
import MobileNavBarMenu from './NavigationBar/MobileNavBarMenu';
import { UserContext } from 'Context';
import logo from 'Assets/cloud(white).svg';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  navbar: {
    width: '100vw',
    height: theme.spacing(8),
    flexDirection: 'row',
    alignItems: 'center',
  },
  logo: {
    height: theme.spacing(7),
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
    paddingTop: theme.spacing(0.5),
  },
  link: {
    padding: `0px ${theme.spacing(2.5)}px`,
    textTransform: 'none',
  },
  menu: {
    marginLeft: 'auto',
  },
}));

/** Navigation bar */
export default function NavigationBar({ tabIndex }: { tabIndex: number | boolean }) {
  const theme = useTheme();
  const { balance, name, reserved } = useContext(UserContext);

  const [value, setValue] = React.useState<number | boolean>(tabIndex);
  const { classes } = useStyles();

  const handleChange = (event: React.ChangeEvent<any>, newValue: number) => setValue(newValue);

  return (
    <AppBar position="fixed" className={classes.navbar}>
      <Box sx={{ display: { xs: 'block', md: 'none' } }}>
        <MobileNavBarMenu />
      </Box>
      <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
        <Link to="/" onClick={(event) => handleChange(event, 0)}>
          <img src={logo} alt="Logo is missing!" className={classes.logo} />
        </Link>
        <Tabs
          value={value}
          onChange={handleChange}
          sx={{ display: 'flex', alignItems: 'center' }}
          textColor="inherit"
          indicatorColor="secondary"
        >
          <Tab component={Link} className={classes.link} label="Печать" to="/" />
          <Tab component={Link} className={classes.link} label="Принтеры" to="/printers" />
          {/*<Tab component={Link} className={classes.link} label="Типография" to="/printingHouse" />*/}
          <Tab component={Link} className={classes.link} label="Оплата" to="/account" />
          <Tab component={Link} className={classes.link} label="Новости" to="/news" />
        </Tabs>
      </Box>
      <Grid item sx={{ marginLeft: 'auto' }}>
        <Box display="flex" height="100%" alignItems="center">
          <Box
            display="flex"
            flexDirection="column"
            height="100%"
            justifyContent="space-evenly"
            marginRight={`${theme.spacing(2.5)}px`}
          >
            <Typography variant="body2" color="inherit">
              {name}
            </Typography>
            <Typography variant="body2" color="inherit">
              {balance} р. {reserved === '0.00' ? '' : `(${reserved} р.)`}
            </Typography>
          </Box>
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <Menu />
          </Box>
        </Box>
      </Grid>
    </AppBar>
  );
}
