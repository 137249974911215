import React from 'react';

import { WebsiteContext } from 'Context';
import { createCookie } from 'Utils';
import StyledCheckbox from './shared/StyledCheckbox';

export default function PrinterStateIconsCheckbox() {
  const { useIconsForPrinterDescriptions: isChecked, updateContext } = React.useContext(WebsiteContext);

  function handleChange() {
    const newState = !isChecked;
    updateContext({ useIconsForPrinterDescriptions: newState });
    createCookie('useIconsForPrinterDescriptions', newState.toString(), 1000);
  }
  return (
    <StyledCheckbox
      tooltip="Данные о принтерах будут отображаться в виде текста/иконок"
      label="Использовать иконки"
      onChange={handleChange}
      isChecked={isChecked}
    />
  );
}
