import React from 'react';
import { CssBaseline, StyledEngineProvider } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';

import Router from './App/Router';
import { LightTheme } from 'Themes';
import { UserContextLoader, WebsiteContextLoader, SocketContextLoader } from 'Context';
import { useWindowDimensions } from 'Utils';

export default function App() {
  // Re-render page on innerWidth and innerHeight change
  useWindowDimensions();
  return (
    <CssBaseline>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={LightTheme}>
          <UserContextLoader>
            <SocketContextLoader>
              <WebsiteContextLoader>
                <Router />
              </WebsiteContextLoader>
            </SocketContextLoader>
          </UserContextLoader>
        </ThemeProvider>
      </StyledEngineProvider>
    </CssBaseline>
  );
}
