import { Box, Checkbox, FormControl, FormControlLabel, MenuItem, Select, Toolbar, Typography } from '@mui/material';

import { PrintFormAction } from 'Pages/PrintPage/shared/useFormManager';
import React from 'react';
import UserContext from 'Context/UserContext/UserContext';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  formControl: {
    marginLeft: theme.spacing(2.5),
    minWidth: 120,
    display: 'flex',
  },
  checkbox: {
    marginLeft: theme.spacing(2.5),
  },
}));

type Props = {
  setBorders: (borders: boolean) => void;
  setAlbum: (album: boolean) => void;
  dispatch: React.Dispatch<PrintFormAction>;
};

export default function PagesPerSheet({ setBorders, dispatch, setAlbum }: Props) {
  const { classes } = useStyles();
  const { activeFile } = React.useContext(UserContext);
  const { print_settings } = activeFile!;

  const { pages_per_sheet, borders, album } = print_settings;

  const values = album ? [2, 8, 18] : [1, 4, 9, 16];

  const handleChange = (event) => {
    const pages_per_sheet = event.target.value;
    const borders = pages_per_sheet > 1;
    dispatch({
      type: 'change_print_settings',
      print_settings: { ...print_settings, pages_per_sheet, borders },
    });
  };

  React.useEffect(() => {
    dispatch({
      type: 'change_print_settings',
      print_settings: {
        ...print_settings,
        pages_per_sheet: values.includes(pages_per_sheet) ? pages_per_sheet : values[0],
        borders: album || pages_per_sheet > 1,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [album]);

  const handleBordersCheckboxClick = (event) => {
    setBorders(!borders);
  };

  const handleAlbumCheckboxClick = (event) => {
    setAlbum(!album);
  };

  return (
    <>
      <Toolbar
        sx={{
          display: { xs: 'flex' },
          flexDirection: 'row',
          justifyContent: 'left',
        }}
      >
        <Typography noWrap component="div" variant="h6" color="inherit">
          Страниц на лист
        </Typography>

        <Box display="flex">
          <FormControl variant="outlined" className={classes.formControl} margin="dense">
            <Select id="pages_per_sheet" value={pages_per_sheet} onChange={handleChange}>
              {values.map((value) => {
                return (
                  <MenuItem value={value} key={value}>
                    {value}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Toolbar>
      <Box display="flex">
        <FormControlLabel
          className={classes.checkbox}
          control={<Checkbox checked={borders} onClick={handleBordersCheckboxClick} name="borders" color="primary" />}
          label="Рамки"
        />

        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox checked={album || false} onClick={handleAlbumCheckboxClick} name="album" color="primary" />
          }
          label="Альбом"
        />
      </Box>
    </>
  );
}
