/**
 * Page renders navbar and some arbitrary html content.
 * @packageDocumentation
 */
import React from 'react';
import { Box, CircularProgress, useTheme } from '@mui/material';
import { Footer, NavigationBar } from 'Components';
import Axios from 'axios';
import { useLocation } from 'react-router-dom';
import { getWindowDimensions } from 'Utils/useWindowDimensions';
import getSpacing from 'Utils/getSpacing';

export default function NewsPage({ tabIndex }: { tabIndex: number | boolean }) {
  const theme = useTheme();

  const [content, setContent] = React.useState('');

  const location = useLocation();

  React.useEffect(() => {
    load();

    async function load() {
      if (content !== '') return;
      try {
        const result = await Axios.get('/page' + location.pathname);
        setContent(result.data);
      } catch (error) {
        console.log(error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { width, height } = getWindowDimensions();

  return (
    <Box width={width} height={height} overflow="hidden">
      <NavigationBar tabIndex={tabIndex} />
      <Box
        position="absolute"
        top={theme.spacing(8)}
        height={height - getSpacing(theme, 12)}
        width="100%"
        bgcolor="background"
        overflow="auto"
      >
        <Box display="flex" width="100%">
          {content === '' ? (
            <Box marginX="auto" marginTop={theme.spacing(5)}>
              <CircularProgress size={50} color="secondary" />
            </Box>
          ) : (
            <Box
              marginX="auto"
              marginTop={theme.spacing(2.5)}
              dangerouslySetInnerHTML={{ __html: content }}
              sx={{
                maxWidth: `${Math.min(1200, window.innerWidth - 40)}px`,
                fontSize: '1.2rem',
                textAlign: 'start',
                [`@media (max-width: 1240px)`]: {
                  container: {
                    fontSize: '1rem',
                  },
                },
                [`@media (max-width: 960px)`]: {
                  container: {
                    fontSize: '0.8rem',
                  },
                },
              }}
            />
          )}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}
