/**
 * Page to display website news. Is available without authentication.
 * @packageDocumentation
 */
import React from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import { getWindowDimensions } from 'Utils/useWindowDimensions';
import getSpacing from 'Utils/getSpacing';
import { CredentialResponse, GoogleLogin } from '@react-oauth/google';
import axios from 'axios';

export default function TestPage() {
  const theme = useTheme();

  const { width, height } = getWindowDimensions();
  const [credentials, setCredentials] = React.useState<CredentialResponse | undefined>(undefined);

  async function googleAuth() {
    try {
      const result = await axios.post('/api/auth/googleAuth', { ...credentials });
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  }
  React.useEffect(() => {
    if (credentials) googleAuth();
  }, [credentials]);
  return (
    <Box width={width} height={height} overflow="hidden">
      <Typography>
        Это тестовая страница. Функционал, представленный здесь предназначен исключительно для тестирования и может не
        работать стабильно или не работать вообще. Возможно, он будет добавлен на основную версию сайта после проверки.
      </Typography>
      <GoogleLogin
        onSuccess={(credentialResponse) => {
          console.log(credentialResponse);
          setCredentials(credentialResponse);
        }}
        onError={() => {
          console.log('Login Failed');
        }}
      />
    </Box>
  );
}
