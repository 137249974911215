/**
 * Registration page
 * @packageDocumentation
 */
import React from 'react';
import { Box, Button, Grid } from '@mui/material';

import { SimpleHeader, RegistrationForm } from 'Components';

export default function RegistrationPage() {
  return (
    <Box
      minHeight="100vh"
      width="100vw"
      bgcolor="background.default"
      display="flex"
      alignItems="center"
      justifyContent="center"
      overflow="auto"
    >
      <Grid container xs={12} sm={6} md={4} lg={3}>
        <Grid item xs={12}>
          <SimpleHeader largeScreenText="Регистрация" smallScreenText="Регистрация" />
        </Grid>
        <Grid item xs={12}>
          <RegistrationForm />
          <Box marginTop="10px">
            <Button
              variant="text"
              href="mailto: info@printmipt.ru? subject = Вход на сервис"
              style={{ textTransform: 'none' }}
            >
              По любым вопросам: info@printmipt.ru
            </Button>
            <Button variant="outlined" href="/login">
              Вход
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
