/**
 * Custom hook for the form state management.
 * @packageDocumentation
 */
import React from 'react';
import Decimal from 'decimal.js';

import { SocketContext, UserContext, UserContextType } from 'Context';
import { PrinterData, FileData, PrintSettings } from 'Interfaces';
import { calculatePrice, matchPrinter } from 'Utils';

// Hook for state management
export default function useFormManager(): [
  state: PrintFormState,
  dispatch: React.Dispatch<PrintFormAction>,
  context: UserContextType,
] {
  const context = React.useContext(UserContext);
  const { printers } = React.useContext(SocketContext);
  const { balance, updateContext } = context;
  const activeFile = context.activeFile!;
  const initialState = { activeFile, printers, balance };
  return [...React.useReducer(reducer, initialState, init), context];

  // Context aware reducer
  function reducer(state: PrintFormState, action: PrintFormAction): PrintFormState {
    switch (action.type) {
      case 'change_print_settings': {
        const { print_settings } = action;
        const currentPrice = calculatePrice(print_settings, activeFile.fill, state.currentPrinter?.toner_price);
        activeFile.print_settings = print_settings;
        updateContext({ activeFile });
        return { ...state, ...{ print_settings, currentPrice } };
      }
      case 'change_printer': {
        const { printer } = action;
        activeFile.print_settings.printer_id = printer.id;
        const currentPrice = calculatePrice(activeFile.print_settings, activeFile.fill, printer.toner_price);
        updateContext({ activeFile });
        return { ...state, ...{ currentPrinter: printer, currentPrice } };
      }
      case 'start_submission': {
        return { ...state, ...{ isSubmitting: true } };
      }
      case 'finish_submission': {
        // If file was printed successfully, update state
        const newBalance = action.balance ? action.balance : balance;
        return { ...state, ...{ isSubmitting: false, balance: newBalance } };
      }
      case 'validate': {
        const { currentPrinter } = state;
        const { print_settings, fill } = activeFile;
        let isValidPrinter = true;
        if (!currentPrinter || matchPrinter(currentPrinter, print_settings, fill.pages) !== '') {
          isValidPrinter = false;
        }

        // Here comes decimal arithmetics
        const isEnoughMoney = state.currentPrice
          ? new Decimal(state.currentPrice).lessThan(new Decimal(balance))
          : true;

        // Validating paper
        const paper = print_settings.sides === 'one-sided' ? fill.pages : Math.ceil(fill.pages / 2);
        const totalPaper = paper * print_settings.copies;

        let isEnoughPaper = true;
        if (currentPrinter) {
          isEnoughPaper = new Decimal(totalPaper).lessThanOrEqualTo(new Decimal(currentPrinter.paper));
        }

        const isValidForm = isValidPrinter && isEnoughMoney && isEnoughPaper;
        let errorMessage = '';
        if (!isEnoughMoney) {
          errorMessage = 'Недостаточно средств на счете';
        } else if (!isEnoughPaper) {
          errorMessage = 'Недостаточно бумаги в принтере';
        }
        return { ...state, isValidPrinter, isValidForm, errorMessage, isEnoughMoney };
      }
      default: {
        throw new Error();
      }
    }
  }
}

// State for the print form
export type PrintFormState = {
  currentPrinter?: PrinterData;
  currentPrice: string;
  isValidForm: boolean;
  errorMessage: string;
  isValidPrinter: boolean;
  isEnoughMoney: boolean;
  isEnoughPaper: boolean;
  isSubmitting: boolean;
};

// Dispatcher action type
export type PrintFormAction =
  | { type: 'change_print_settings'; print_settings: PrintSettings }
  | { type: 'change_printer'; printer: PrinterData }
  | { type: 'start_submission' }
  | { type: 'finish_submission'; balance?: string }
  | { type: 'validate' };

// Props for the init function
type InitProps = {
  activeFile: FileData;
  printers: PrinterData[];
  balance: string;
};

// Init function for the reducer
function init(initialState: InitProps): PrintFormState {
  const { activeFile, printers, balance } = initialState;
  const { print_settings: printSettings, fill } = activeFile;
  const currentPrinter = printSettings.printer_id
    ? printers.find((printer) => printer.id === printSettings.printer_id)
    : undefined;

  // Validating money
  const currentPrice = currentPrinter ? calculatePrice(printSettings, fill, currentPrinter.toner_price) : '';
  const isEnoughMoney = currentPrice ? new Decimal(balance).gte(new Decimal(currentPrice)) : true;
  // Validating paper
  const paper = printSettings.sides === 'one-sided' ? fill.pages : Math.ceil(fill.pages / 2);
  const totalPaper = paper * printSettings.copies;

  let isEnoughPaper = true;
  if (currentPrinter) {
    isEnoughPaper = new Decimal(totalPaper).lessThanOrEqualTo(new Decimal(currentPrinter.paper));
  }

  return {
    currentPrinter,
    currentPrice,
    isValidForm: false,
    errorMessage: '',
    isValidPrinter: false,
    isEnoughMoney,
    isEnoughPaper,
    isSubmitting: false,
  };
}
