/**
 * Select the number of copies for the document.
 * @packageDocumentation
 */

import * as Yup from 'yup';

import { TextField, Toolbar, Typography, useTheme } from '@mui/material';

import React from 'react';

// Validation schema for copies text field
const CopiesSchema = Yup.object().shape({
  copies: Yup.number().typeError().positive().integer(),
});

type Props = {
  copies: number;
  setCopies(copies: number): void;
};

// Copies number set up
export default function CopiesField({ copies, setCopies }: Props) {
  const [helperText, setHelperText] = React.useState<string>('');
  const theme = useTheme();
  const error_message = 'Введите целое положительное число';

  const handleBlur = async (event: React.FocusEvent<HTMLSpanElement>) => {
    try {
      const copies = (event.target as any).value;
      const valid = await CopiesSchema.validate({ copies: copies });
      setCopies(valid?.copies as number);
      setHelperText('');
    } catch (error) {
      setHelperText(error_message);
    }
  };

  const handleChange = async (event: React.ChangeEvent<HTMLSpanElement>) => {
    try {
      const copies = (event.target as any).value;
      if (copies === '') return;
      const valid = await CopiesSchema.validate({ copies: copies });
      setCopies(valid?.copies as number);
      setHelperText('');
    } catch (error) {
      setHelperText(error_message);
    }
  };

  return (
    <Toolbar
      sx={{
        display: { xs: 'flex' },
        flexDirection: 'row',
        justifyContent: 'left',
      }}
    >
      <Typography noWrap component="div" variant="h6" color="inherit">
        Число копий
      </Typography>

      <TextField
        sx={{ marginLeft: theme.spacing(2.5) }}
        defaultValue={copies}
        error={helperText !== ''}
        variant="outlined"
        helperText={helperText}
        onBlur={handleBlur}
        onChange={handleChange}
        label={
          <Typography variant="body1" color="textPrimary">
            Число копий
          </Typography>
        }
      />
    </Toolbar>
  );
}
