/**
 * Table that shows all of the user files
 * @packageDocumentation
 */
import React from 'react';
import axios from 'axios';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Hidden,
  useTheme,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { SocketContext, UserContext } from 'Context';
import FileTableRow from './FileTable/FileTableRow';
import { FileData } from 'Interfaces';
import { accessCookie } from 'Utils';
import { FileStatusPriority } from './shared/useFileStatus';
import getSpacing from 'Utils/getSpacing';

type Props = {
  showPrintQueue: boolean;
  showPrintHistory: boolean;
  setActiveFile(file: FileData): void;
  setAlertState(newState: any): void;
};

/** User File Table */
export default function FileTable({ setActiveFile, showPrintQueue, showPrintHistory, setAlertState }: Props) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { files, updateContext, defaultPrinter } = React.useContext(UserContext);
  const { printers } = React.useContext(SocketContext);

  // Print file
  const printFile = (file: FileData): void => {
    if (defaultPrinter && !file.print_settings.printer_id) file.print_settings.printer_id = defaultPrinter;
    setActiveFile(file);
    navigate('/print');
  };

  // On delete button click
  const deleteFile = async (fileName: string): Promise<void> => {
    try {
      const token = accessCookie('token');
      const response = await axios.post(
        '/api/file/delete',
        { name: fileName },
        {
          headers: { authorization: 'Bearer ' + token },
        },
      );
      const { files } = response.data;
      updateContext({ files });
      setAlertState({ text: 'Файл удален', severity: 'success', isOpened: true });
    } catch (error: any) {
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        // Displaying alert message
        setAlertState({ text: error.response.data, severity: 'warning', isOpened: true });
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    }
  };

  // Sorting rows uploaded files go first
  const sorted_files = files.sort((a: FileData, b: FileData) => {
    const aPriority = FileStatusPriority[a.status];
    const bPriority = FileStatusPriority[b.status];
    // If statuses have the same priority- sort by date
    if (aPriority === bPriority) return new Date(b.date).getTime() - new Date(a.date).getTime();
    // Else sort by priority
    return aPriority - bPriority;
  });

  // Filtering based on checkboxes
  const filtered_files = sorted_files.filter((value) => {
    if (['printing', 'uploaded', 'settings saved', 'queued'].includes(value.status) && showPrintQueue) return true;
    if (['printed', 'error encountered', 'print error'].includes(value.status) && showPrintHistory) return true;
    return false;
  });

  const printerNames = filtered_files.map((file) => {
    const printer_id = file.print_settings.printer_id || null;
    if (!printer_id) return '';
    const index = printers.findIndex((printer) => printer.id === printer_id);
    return printers[index] ? printers[index].name : '';
  });

  return (
    <Paper style={{ width: '100vw', overflow: 'hidden' }}>
      <TableContainer style={{ maxHeight: window.innerHeight - getSpacing(theme, 32), overflow: 'auto' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <Hidden mdDown>
                <TableCell sx={{ width: theme.spacing(5), padding: theme.spacing(0.5, 1) }}>Печать</TableCell>
              </Hidden>
              <TableCell sx={{ padding: theme.spacing(0.5, 1), maxWidth: '30vw', overflow: 'hidden' }}>Файл</TableCell>
              <TableCell sx={{ padding: theme.spacing(0.5, 1) }}>Статус</TableCell>
              <TableCell sx={{ padding: theme.spacing(0.5, 1) }}>Принтер</TableCell>
              <TableCell sx={{ padding: theme.spacing(0.5, 1) }} align="center">
                Дата
              </TableCell>
              <TableCell sx={{ width: theme.spacing(5), padding: theme.spacing(0.5, 1) }} />
            </TableRow>
          </TableHead>
          <TableBody>
            {files &&
              filtered_files.map((file: FileData, index: number) => {
                return (
                  <FileTableRow
                    key={file.system_name}
                    file={{ ...file }}
                    printFile={printFile}
                    deleteFile={deleteFile}
                    printer={printerNames[index]}
                  />
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
