import React from 'react';
import { Checkbox, FormControlLabel, Typography, Tooltip } from '@mui/material';
import { CheckBoxOutlineBlankOutlined } from '@mui/icons-material';

type Props = {
  tooltip?: string;
  label: string;
  isChecked: boolean;
  onChange: () => void;
};
// Generic checbox with the tooltip
export default function StyledCheckbox({ tooltip, isChecked, onChange, label }: Props) {
  return (
    <Tooltip
      title={
        tooltip ? (
          <Typography variant="body2" color="inherit">
            {tooltip}
          </Typography>
        ) : (
          ''
        )
      }
    >
      <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={onChange}
            name="TableSettings"
            color="primary"
            icon={<CheckBoxOutlineBlankOutlined color="primary" />}
          />
        }
        label={
          <Typography variant="body2" color="inherit">
            {label}
          </Typography>
        }
      />
    </Tooltip>
  );
}
