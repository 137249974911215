/**
 * This file defines global context object
 * @packageDocumentation
 */
import React from 'react';

import { FileData, News, Payment } from 'Interfaces';

/** This is UserContext which is used exstensively by website components */
export type UserContextType = {
  _id: string;
  name: string /** user name */;
  balance: string /** user account balance */;
  reserved: string;
  files: FileData[] /** files uploaded by user */;
  activeFile: FileData | null /** selected file */;
  currentPrinter: string | null /** current printer */;
  favouritePrinters: string[] /** favourite printers ids */;
  news: News[];
  payments: Payment[];
  oauth?: OauthHandles;
  defaultPrinter?: string;
};

export type OauthHandles = {
  google?: string;
  yandex?: string;
  vk?: string;
};

/** Default value to initialize user context in app.tsx */
export const userContext: UserContextType = {
  _id: '',
  name: '',
  balance: '0.00',
  reserved: '0.00',
  files: [],
  activeFile: null,
  currentPrinter: null,
  favouritePrinters: [],
  news: [],
  payments: [],
};

/** Context initializator */
export const UserContext = React.createContext({
  ...userContext,
  updateContext: (newData: Partial<UserContextType>) => {},
  setContext: (value: React.SetStateAction<UserContextType>) => {},
});

export default UserContext;
