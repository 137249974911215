/**
 * Printer selection menu
 * @packageDocumentation
 */

import { Box, Button, Menu, Typography, useTheme } from '@mui/material';
import { PrintSettings, PrinterData } from 'Interfaces';
import { SocketContext, UserContext, WebsiteContext } from 'Context';
import { makeStyles, withStyles } from 'tss-react/mui';

import { PrintFormAction } from '../shared/useFormManager';
import PrintersMenuItem from './PrintersMenuField/PrintersMenuItem';
import React from 'react';
import { matchPrinter } from 'Utils';

const useButtonStyles = makeStyles()((theme) => ({
  valid: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
  },
  invalid: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
  },
}));

type Props = {
  printSettings: PrintSettings;
  isValidPrinter: boolean;
  isEnoughPaper: boolean;
  currentPrinter?: PrinterData;
  pages: number;
  dispatch: React.Dispatch<PrintFormAction>;
};

export default function PrintersMenu({
  printSettings,
  isValidPrinter,
  isEnoughPaper,
  currentPrinter,
  pages,
  dispatch,
}: Props) {
  const theme = useTheme();
  const { classes } = useButtonStyles();

  const { favouritePrinters, defaultPrinter } = React.useContext(UserContext);
  const { printers } = React.useContext(SocketContext);
  const { useIconsForPrinterDescriptions: useIcons } = React.useContext(WebsiteContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const defaultPrinterName = printers.find((printer) => printer.id === defaultPrinter)?.name;
  const default_name = defaultPrinterName ? `Принтер: ${defaultPrinterName}` : 'Выбор принтера';

  function changePrinter(printer: PrinterData) {
    setAnchorEl(null);
    dispatch({ type: 'change_printer', printer });
  }

  const rows: { printer: PrinterData; favourite: boolean }[] = printers.map((printer) => {
    const favourite = favouritePrinters.includes(printer.id);
    return { printer, favourite };
  });
  const sorted_rows = rows.sort((a, b) => {
    // Favourite printers rise to the top
    if (a.favourite && !b.favourite) return -1;
    if (b.favourite && !a.favourite) return 1;
    // If both printers have the same level use printer match settings
    if (matchPrinter(a.printer, printSettings, pages) === '' && matchPrinter(b.printer, printSettings, pages) !== '')
      return -1;
    if (matchPrinter(b.printer, printSettings, pages) === '' && matchPrinter(a.printer, printSettings, pages) !== '')
      return 1;
    const nameA = parseInt(a.printer.name);
    const nameB = parseInt(b.printer.name);
    if (isNaN(nameA)) return -1;
    if (isNaN(nameB)) return 1;
    return nameA >= nameB ? 1 : -1;
  });

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  return (
    <Box display="flex" flexDirection="column">
      <Button
        variant="outlined"
        classes={{ root: (isValidPrinter && isEnoughPaper) || !currentPrinter ? classes.valid : classes.invalid }}
        onClick={openMenu}
        style={{ textTransform: 'none' }}
      >
        <Typography variant="body1">{currentPrinter ? 'Принтер: ' + currentPrinter.name : default_name}</Typography>
      </Button>
      {isValidPrinter || !currentPrinter ? (
        ''
      ) : (
        <Typography variant="caption" style={{ color: theme.palette.error.main }}>
          {matchPrinter(currentPrinter, printSettings, pages)}
        </Typography>
      )}
      <StyledMenu
        id="printer-selection"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        sx={{
          paper: {
            border: '1px solid #d3d4d5',
          },
        }}
      >
        {sorted_rows.map((row) => (
          <PrintersMenuItem
            key={row.printer.id}
            disabled={matchPrinter(row.printer, printSettings, pages) !== ''}
            changePrinter={changePrinter}
            useIcons={useIcons}
            {...row}
          />
        ))}
      </StyledMenu>
    </Box>
  );
}

// HOC for some stylings.
const StyledMenu = withStyles(Menu, () => ({
  paper: {
    border: '1px solid #d3d4d5',
  },
}));
