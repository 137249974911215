import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import UserContext from 'Context/UserContext/UserContext';
import { Payment } from 'Interfaces';
import React from 'react';
import { mdWidth } from 'Themes';
import { getWindowDimensions } from 'Utils/useWindowDimensions';
import { getSpacing } from 'Utils';

const useStyles = makeStyles()((theme) => ({
  table: {
    maxHeight: '50vh',
    overflow: 'auto',
    maxWidth: `calc(100vw-${theme.spacing(5)})`,
    marginRight: theme.spacing(2.5),
  },
  tableCell: { padding: `${theme.spacing(0.5)} ${theme.spacing(1)}` },
  [`@media (max-width: ${mdWidth}px)`]: {
    tableCell: {
      fontSize: '0.6em',
      padding: `0px ${theme.spacing(1)}`,
    },
  },
  [`@media (min-width: ${mdWidth}px)`]: {
    box: {
      marginTop: theme.spacing(0.5),
      marginLeft: theme.spacing(0),
      marginRight: theme.spacing(2.5),
    },
  },
}));

export default function PaymentsTable() {
  const { payments } = React.useContext(UserContext);
  const { classes } = useStyles();
  const { width } = getWindowDimensions();
  const theme = useTheme();

  const sorted_payments =
    payments.length > 0 ? payments.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()) : [];
  return (
    <>
      {payments.length > 0 ? (
        <>
          <Typography variant="body1">История пополнений</Typography>
          <Box
            sx={{
              maxWidth: width > mdWidth ? width - getSpacing(theme, 5) : width,
              marginTop: theme.spacing(0.5),
              marginLeft: width > mdWidth ? 0 : theme.spacing(-2.5),
            }}
          >
            <TableContainer component={Paper} className={classes.table}>
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell className={classes.tableCell}>Дата</TableCell>
                    <TableCell className={classes.tableCell}>Сумма</TableCell>
                    <TableCell className={classes.tableCell}>Карта</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sorted_payments.map((payment: Payment) => (
                    <TableRow key={payment.id}>
                      <TableCell className={classes.tableCell}>{new Date(payment.date).toUTCString()}</TableCell>
                      <TableCell className={classes.tableCell}>{payment.sum}</TableCell>
                      <TableCell className={classes.tableCell}>{payment.card_number}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </>
      ) : (
        ''
      )}
    </>
  );
}
