import React from 'react';
import { Card, CardMedia, CardContent, Divider, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { useNavigate } from 'react-router';

const useStyles = makeStyles()((theme) => ({
  cardActive: {
    width: Math.min(300, window.innerWidth),
    height: 350,
    overflow: 'auto',
    marginBottom: theme.spacing(5),
    transition: '0.3s',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
    '&:hover': {
      boxShadow: '0 32px 120px -12px rgba(0,0,0,0.3)',
      cursor: 'pointer',
    },
  },
  cardPassive: {
    width: Math.min(300, window.innerWidth),
    height: 350,
    overflow: 'auto',
    marginBottom: theme.spacing(5),
    transition: '0.3s',
    boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
  },
  media: {
    paddingTop: '40%',
  },
  content: {
    textAlign: 'left',
    padding: theme.spacing(2),
  },
  divider: {
    margin: `${theme.spacing(1)} 0`,
  },
}));

type Props = {
  date: string;
  title: string;
  text: string;
  image?: string;
  url: string;
};

export default function NewsCard({ date, title, text, image, url }: Props) {
  const { classes } = useStyles();
  const navigate = useNavigate();

  function handleClick() {
    if (url) navigate(url);
  }

  return (
    <Card className={url ? classes.cardActive : classes.cardPassive} onClick={() => handleClick()}>
      <CardMedia className={classes.media} image={image} />
      <CardContent className={classes.content}>
        <Typography variant="subtitle2">{date}</Typography>
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
        <Divider className={classes.divider} light />
        <Typography variant="body2">{text}</Typography>
      </CardContent>
    </Card>
  );
}
