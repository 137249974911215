/**
 * Loads website settings
 * @packageDocumentation
 */
import React from 'react';

import { accessCookie } from 'Utils';
import { WebsiteContextType, websiteContext, WebsiteContext } from './WebsiteContext/WebsiteContext';

// Context loader hook
export default function WebsiteContextLoader(props: any) {
  const [state, setState] = React.useState(websiteContext);
  const updateWebsiteContext = (newData: Partial<WebsiteContextType>) => setState((data) => ({ ...data, ...newData }));
  // Loader
  React.useEffect(() => {
    try {
      const cookie = accessCookie('useIconsForPrinterDescriptions');
      const settings = cookie === 'true' ? true : false;
      if (!settings) updateWebsiteContext({ useIconsForPrinterDescriptions: false });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <WebsiteContext.Provider value={{ ...state, updateContext: updateWebsiteContext }}>
      {props.children}
    </WebsiteContext.Provider>
  );
}
