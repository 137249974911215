import { Box, useTheme } from '@mui/material';

import Banner from './LandingPage/Banner';
import FirstScreen from './LandingPage/FirstScreen';
import LandingFooter from './LandingPage/LandingFooter';
import Questions from './LandingPage/Questions';
import React from 'react';
import TopBar from './LandingPage/LandingTopBar';
/**
 * The most beautiful page on the webswite (for now).
 * @packageDocumentation
 */

/**
 * Landing page is displayed if user visits website for the first time or after logout or token expiration.
 */
export default function LandingPage() {
  const theme = useTheme();

  const [clientWidth, setClientWidth] = React.useState<number | undefined>(undefined);

  React.useEffect(() => {
    function handleResize() {
      setClientWidth(document.getElementById('landingPage')?.clientWidth);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <Box id="landingPage" width={clientWidth} sx={{ overflow: 'auto' }}>
      <TopBar />
      <Box position="absolute" top={theme.spacing(8)}>
        <FirstScreen />
        <Banner />
        <Questions />
        <LandingFooter />
      </Box>
    </Box>
  );
}
