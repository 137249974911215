/**
 * Ui-version of login form
 * @packageDocumentation
 */
import axios from 'axios';
import * as Yup from 'yup';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Grid, useTheme, Typography, InputAdornment, IconButton } from '@mui/material';
import { TextField } from 'formik-mui';
import { Formik, Form, Field, FormikHelpers } from 'formik';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { Alert, useAlert } from './shared/Alert';
import { UserContext } from 'Context';
import { createCookie } from 'Utils';
import { LoginResponse, isLoginResponse } from 'Interfaces';
import { OauthPayload } from './RegistrationForm';
import OauthButtons from './shared/OauthButtons';

const LogInSchema = Yup.object().shape({
  name: Yup.string().max(30, 'Длиннее 30 символов').required('Обязательно'),
  password: Yup.string().max(20, 'Длиннее 20 символов').required('Обязательно'),
});

/** LogInForm component */
export default function LoginForm() {
  const navigate = useNavigate();
  const theme = useTheme();

  // For the password field
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const { updateContext } = useContext(UserContext);

  // Alert message
  const { alertState, setAlertState, handleAlertClose } = useAlert();

  // Form payload
  const [payload, setPayload] = React.useState<OauthPayload | undefined>();

  /** Function is used to send http login request. */
  async function SendLoginPayload(payload: LoginPayload) {
    try {
      const response = await axios.post('/api/auth/login', { ...payload });
      /** Type Guard */
      if (!isLoginResponse(response.data)) {
        throw new Error('Type Guard condition did not fulfill');
      }
      const data: LoginResponse = response.data;

      /** Updating context and saving */
      const { _id, name, token, balance, reserved, news, oauth } = data;
      createCookie('token', token, 30);
      updateContext({ _id, name, balance, reserved, news, oauth });
      // This is needed to avoid memory leak on update state of unmounted component

      navigate('/');
    } catch (error: any) {
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        // Displaying alert message
        setAlertState({ severity: 'error', text: error.response.data, isOpened: true });
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    }
  }

  React.useEffect(() => {
    if (payload && ['google', 'yandex', 'vk'].includes(payload.type)) {
      SendLoginPayload(payload);
    }
  }, [payload]);

  return (
    <>
      <Formik
        initialValues={{ name: '', password: '' }}
        validationSchema={LogInSchema}
        validateOnBlur={true}
        enableReinitialize
        onSubmit={async (values, actions) => {
          await SendLoginPayload({
            type: 'regular',
            name: values.name,
            password: values.password,
          });
          actions.setSubmitting(false);
        }}
      >
        {({ handleBlur, values }) => (
          <Box bgcolor="background.default" boxShadow={3} borderRadius="8px" display="flex" justifyContent="center">
            <Grid item xs={10}>
              <Form>
                <Field
                  component={TextField}
                  name="name"
                  variant="outlined"
                  value={values.name}
                  label={
                    <Typography variant="body1" color="textPrimary">
                      Логин
                    </Typography>
                  }
                  fullWidth
                  sx={{ marginTop: theme.spacing(4) }}
                />

                <Field
                  component={TextField}
                  value={values.password}
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          size="large"
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  name="password"
                  variant="outlined"
                  label={
                    <Typography variant="body1" color="textPrimary">
                      Пароль
                    </Typography>
                  }
                  fullWidth
                  sx={{ marginTop: theme.spacing(4) }}
                  onBlur={handleBlur}
                />
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  sx={{ width: '100%', height: theme.spacing(6), margin: `${theme.spacing(4)} 0px` }}
                >
                  Вход
                </Button>
              </Form>
              <Box marginBottom={theme.spacing(4)}>
                <OauthButtons setPayload={setPayload} />
              </Box>
            </Grid>
          </Box>
        )}
      </Formik>
      <Alert autoHideDuration={10000} state={alertState} onClose={handleAlertClose} />
    </>
  );
}

type LoginPayload = { type: 'regular'; name: string; password: string } | OauthPayload;
