import React from 'react';
import axios from 'axios';
import { Tooltip, Typography, IconButton } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';

import { UserContext } from 'Context';
import { accessCookie } from 'Utils';
import { AlertState } from 'Components/shared/Alert';

type Props = {
  isFavourite: boolean;
  printerId: string;
  setAlertState(newState: AlertState): void;
};

// Returns a star shaped button to add/remove printer from the list of favourites
export default function StarCell({ isFavourite, printerId, setAlertState }: Props) {
  const { updateContext } = React.useContext(UserContext);
  // Adds item to favourites list
  const addFavourite = async (id: string): Promise<void> => {
    try {
      const token = accessCookie('token');
      const response = await axios.post(
        '/api/printer/addFavourite',
        { printerId: id },
        {
          headers: { authorization: 'Bearer ' + token },
        },
      );
      const { favouritePrinters } = response.data;
      updateContext({ favouritePrinters });
    } catch (error: any) {
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        // Displaying alert message
        setAlertState({ text: error.response.data, severity: 'warning', isOpened: true });
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    }
  };

  // Removes item from favourites list
  const removeFavourite = async (id: string): Promise<void> => {
    try {
      const token = accessCookie('token');
      const response = await axios.post(
        '/api/printer/removeFavourite',
        { printerId: id },
        {
          headers: { authorization: 'Bearer ' + token },
        },
      );
      const { favouritePrinters } = response.data;
      updateContext({ favouritePrinters });
    } catch (error: any) {
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        // Displaying alert message
        setAlertState({ text: error.response.data, severity: 'warning', isOpened: true });
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    }
  };
  return (
    <>
      {isFavourite ? (
        <Tooltip title={<Typography variant="body1">Убрать из избранного</Typography>}>
          <IconButton onClick={() => removeFavourite(printerId)} size="small">
            <StarIcon color="secondary" fontSize="small" />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title={<Typography variant="body1">В избранное</Typography>}>
          <IconButton onClick={() => addFavourite(printerId)} size="small">
            <StarBorderIcon color="inherit" fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
    </>
  );
}
