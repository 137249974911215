/**
 * Menu on the top right corner of the navigation bar
 * @packageDocumentation
 */

import { IconButton, Typography, useTheme } from '@mui/material';
import { StyledMenu, StyledMenuItem } from './shared/StyledMenu';
import { UserContext, userContext } from 'Context';

import AccountCircle from '@mui/icons-material/AccountCircleRounded';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import React from 'react';
import { useNavigate } from 'react-router-dom';

/** Menu for the user interface */
export default function NavigationBarMenu() {
  const theme = useTheme();
  const { name, updateContext } = React.useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const Logout = () => {
    updateContext(userContext);
    document.cookie = 'token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
  };

  return (
    <>
      <IconButton color="inherit" aria-label="open menu" component="span" onClick={handleClick} size="large">
        <AccountCircle fontSize="large" />
      </IconButton>
      <StyledMenu
        id="user-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <Typography variant="body1" color="inherit" sx={{ paddingLeft: 2 }}>
          {name}
        </Typography>
        <StyledMenuItem onClick={() => navigate('/account')}>Личный кабинет</StyledMenuItem>
        <StyledMenuItem onClick={Logout}>
          <ExitToAppIcon fontSize="small" />
          <Typography sx={{ paddingLeft: theme.spacing(1) }}>Выйти</Typography>
        </StyledMenuItem>
      </StyledMenu>
    </>
  );
}
