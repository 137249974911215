import { Button, Grid, Typography, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { LoginForm, RegistrationForm } from 'Components';
import { useNavigate } from 'react-router';

import Background from 'Assets/Printers.jpg';
import { Box } from '@mui/system';
import React from 'react';
import { getWindowDimensions } from 'Utils/useWindowDimensions';
import { getSpacing } from 'Utils';

const useStyles = makeStyles()((theme) => ({
  texPart: {
    alignSelf: 'flex-start',
    marginTop: theme.spacing(5),
  },
  text: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  button: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  email: {
    textDecoration: 'none',
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1),
  },
  // Creates semi-transparent background image
  backgroundImageDiv: {
    '&:after': {
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      top: 0,
      backgroundImage: `url(${Background})`,
      backgroundSize: 'cover',
      filter: 'brightness(10%)',
      opacity: '0.8',
      zIndex: -1,
    },
  },
}));

export default function FirstScreen() {
  const theme = useTheme();
  const { classes } = useStyles();
  const navigate = useNavigate();

  const { height } = getWindowDimensions();

  return (
    <Box
      className={classes.backgroundImageDiv}
      width="100%"
      minHeight={height * 0.8 - getSpacing(theme, 8)}
      overflow="auto"
    >
      <Box
        display="flex"
        alignItems="center"
        minHeight={height * 0.8 - getSpacing(theme, 8)}
        width="100%"
        flexWrap="wrap"
      >
        <Grid container>
          <Grid item xs={1} />

          <Grid item xs={10} md={5}>
            <Typography variant="h2" color="textSecondary" style={{ marginTop: theme.spacing(2.5) }}>
              Физтех-Печать
            </Typography>
            <Typography variant="h6" color="textSecondary">
              Создано студентами для студентов
            </Typography>
            <Typography variant="body1" color="textSecondary" className={classes.text}>
              Сервис печати документов на общедоступной сети принтеров по кампусу МФТИ, который помогает студентам
              распечатывать любые форматы файлов. Достаточно загрузить документ на сайт и забрать его в ближайшем
              принтере. От 2,5 рублей за лист, принтеры обслуживаются 24x7, доступ с любого устройства
            </Typography>
          </Grid>

          <Grid item xs={12} md={6}>
            <Box display="flex" justifyContent="center">
              <Grid item xs={12} sm={8} md={8}>
                <RegistrationForm />
                <Box display="flex" justifyContent="center">
                  <Grid item xs={10}>
                    <Button
                      variant="contained"
                      color="secondary"
                      className={classes.button}
                      onClick={() => navigate('/login')}
                      fullWidth
                    >
                      Войти
                    </Button>
                  </Grid>
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
