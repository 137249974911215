/**
 * Page to display website news. Is available without authentication.
 * @packageDocumentation
 */

import { Box, useTheme } from '@mui/material';
import { Footer, NavigationBar } from 'Components';

import NewsGrid from './NewsPage/NewsGrid';
import React from 'react';
import getSpacing from 'Utils/getSpacing';
import { getWindowDimensions } from 'Utils/useWindowDimensions';

export default function NewsPage() {
  const theme = useTheme();

  const { width, height } = getWindowDimensions();

  return (
    <Box width={width} height={height} overflow="hidden">
      <NavigationBar tabIndex={4} />
      <Box
        position="absolute"
        top={theme.spacing(8)}
        height={height - getSpacing(theme, 12)}
        width="100%"
        bgcolor="background"
        overflow="auto"
      >
        <Box marginTop={theme.spacing(5)}>
          <NewsGrid />
        </Box>
      </Box>
      <Footer />
    </Box>
  );
}
