import { Box, Paper, Typography } from '@mui/material';

import Dropdown from './Questions/Dropdown';
import React from 'react';

export default function Benefits() {
  const [number, setNumber] = React.useState<number | null>(null);
  const updateNumber = (newNumber: number) => setNumber((number) => (newNumber !== number ? newNumber : null));
  return (
    <Paper>
      <Box width="100%" bgcolor="background.default" marginTop="20px" padding="22px">
        <Typography variant="h4" color="textPrimary" align="center">
          {' '}
          Частые вопросы{' '}
        </Typography>
        <Box maxWidth={1200} display="flex" flexDirection="column" margin="auto">
          {Items.map((item, index) => (
            <Dropdown
              header={item.header}
              text={item.content}
              number={index}
              key={index}
              setOpenedNumber={updateNumber}
              opened={number === index}
            />
          ))}
        </Box>{' '}
      </Box>
    </Paper>
  );
}

const Items = [
  {
    header: 'Как пользоваться системой печати?',
    content: (
      <Typography variant="body1">
        Нашей системой можно пользоваться с любого устройства, достаточно авторизироваться на сайте, загрузить документ
        для печати, выбрать ближайший принтер и забрать документ из лотка напечатанных документов.
      </Typography>
    ),
  },
  {
    header: 'Сколько это стоит?',
    content: (
      <div>
        <Typography variant="body1">
          Лист черно-белой печати от 2.5 до 5 рублей
          <br />
          Лист цветной печати- от 5 до 7.5 рублей <br />
        </Typography>
        <Typography variant="body1">
          В стоимость печати включены две составляющие: стоимость бумаги и тонера. <br />
          Стоимость тонера = процент заполнения х 0.05р (процент заполнения &lt; 30%), 2.5р (процент заполнения &gt;
          30%) <br />
          Стоимость бумаги = 2.5р (для черно-белой печати), 5р (для цветной печати). <br />
          Стоимость печати = (Стоимость тонера + Стоимость бумаги) х количество листов
          <br /> * для двусторонней печати стоимость тонера х 2
        </Typography>
      </div>
    ),
  },
  {
    header: 'Что делать, если принтер сломался во время моей печати?',
    content: (
      <Typography align="left" variant="body1">
        Мы сожалеем, что вы попали в такую ситуацию. Пожалуйста, пришлите нам Ваш файл для тестирования на почту{' '}
        <b> info@printmipt.ru</b>, мы вернем ВАМ оплату и начислим бонусные баллы
      </Typography>
    ),
  },
  {
    header: 'Что делать, если у меня был аккаунт на старом сайте?',
    content: (
      <Typography align="left" variant="body1">
        Cейчас мы переносим пользователей старого сайта print.mipt.ru на наш новый, более стабильный сайт printmipt.ru.
        Если у вас были деньги на аккаунте Физтех.печати и вы хотети перенести их на новый сайт (даже под другим
        логином), напишите, пожалуйста, нам на почту <b> info@printmipt.ru</b>
      </Typography>
    ),
  },
  {
    header: 'Каковы дальнейшие планы на систему?',
    content: (
      <Typography variant="body1">
        У нас есть множество идей, которые мы хотим реализовать в будущем: совсем скоро мы увеличим количество
        принтеров, добавим больше цветных принтеров, поставим сканеры и копиры, добавим геопозицию по принтерам на
        карту, создадим бонусные программы, сделаем форму обратной связи. Если у Вас есть предложения,поделитесь,
        пожалуйста, через <b>info@printmipt.ru</b>
      </Typography>
    ),
  },
  {
    header: 'Как оставить отзыв?',
    content: (
      <Typography variant="body1">
        Будем рады, если поделитесь своими впечатлениями на своих страницах социальных сетей или пришлете нам наш отзыв
        через <b>info@printmipt.ru</b>
      </Typography>
    ),
  },
];
