/**
 * Pdf preview component.
 * @packageDocumentation
 */
import React, { useState } from 'react';
import { Box, Typography, Paper, Tooltip, IconButton, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import FileDownloadIcon from '@mui/icons-material/Download';
import UserContext from 'Context/UserContext/UserContext';
import getSpacing from 'Utils/getSpacing';

const useStyles = makeStyles()((theme) => ({
  download: {
    marginLeft: 'auto',
  },
  preview: {
    marginLeft: theme.spacing(2.5),
    marginTop: theme.spacing(2.5),
  },
  document: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  page_with_borders: {
    outline: '1px solid black',
    position: 'relative',
    zIndex: 1,
  },
  page_without_borders: {},
}));

// Displays pdf document in the preview after it is loaded.
export default function PreviewDocument({ file }: { file: string }) {
  const theme = useTheme();
  const { classes } = useStyles();

  // If we are loading preview, active file is definitely not null.
  const { activeFile } = React.useContext(UserContext);
  const { print_settings } = activeFile!;
  const { pages_per_sheet, borders, album } = print_settings;

  // Total number of pages, a constant
  const [totalPages, setTotalPages] = useState<number | null>(null);
  // Percieved number of pages depending on grid size
  const [percievedTotalPages, setPercievedTotalPages] = useState<number | null>(null);
  // Current page number
  const [pageNumber, setPageNumber] = useState<number>(1);

  const gridSizeX = album ? Math.sqrt(2 * pages_per_sheet) : Math.sqrt(pages_per_sheet);
  const gridSizeY = album ? gridSizeX / 2 : Math.sqrt(pages_per_sheet);

  const onDocumentLoad = ({ numPages }: { numPages: number }) => {
    setTotalPages(numPages);
    setPercievedTotalPages(Math.ceil(numPages / pages_per_sheet));
  };

  React.useEffect(() => {
    if (!totalPages) return;

    setPercievedTotalPages(Math.ceil(totalPages / pages_per_sheet));
    setPageNumber(1);
  }, [pages_per_sheet, album, totalPages]);

  const changePage = (page: number) => {
    if (page <= 0 || (percievedTotalPages && page > percievedTotalPages)) {
      return;
    }
    setPageNumber(page);
  };

  const calculateHeight = (): number => {
    if (window.innerWidth < 960) return ((window.innerWidth - getSpacing(theme, 5)) * 297) / 210;
    const maxWidth = window.innerWidth * 0.5;
    // These are navbar + marginTop + bottom bar with buttons
    const maxHeight = window.innerHeight - getSpacing(theme, 8) - getSpacing(theme, 7.5) - getSpacing(theme, 8);
    return Math.min((maxWidth * 297) / 210, maxHeight);
  };

  const showBorders = borders ? classes.page_with_borders : classes.page_without_borders;
  const documentHeight = calculateHeight();
  const documentWidth = (documentHeight * 210) / 297;
  const pageHeight = album ? documentWidth / gridSizeY : documentHeight / gridSizeY;
  const pageWidth = album ? documentHeight / gridSizeX : documentWidth / gridSizeX;
  // Render document only if all of the states are updated.
  const isValidPercievedPage = totalPages && (pageNumber - 1) * pages_per_sheet + 1 <= totalPages;
  const albumTranslation = (documentHeight - documentWidth) / 2;

  return (
    <Box className={classes.preview}>
      {window.innerWidth < 960 ? (
        <Typography variant="h4" color="inherit">
          Предпросмотр
        </Typography>
      ) : (
        false
      )}
      <Box display="flex" justifyContent="start" alignItems="center" maxWidth={documentWidth}>
        <Typography variant="body1">
          Страница {pageNumber} из {percievedTotalPages}
        </Typography>
        <Tooltip title={<Typography variant="body1">Назад</Typography>}>
          <IconButton onClick={() => changePage(pageNumber - 1)} size="large">
            <NavigateBeforeIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={<Typography variant="body1">Вперед</Typography>}>
          <IconButton onClick={() => changePage(pageNumber + 1)} size="large">
            <NavigateNextIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={<Typography variant="body1">Скачать</Typography>}>
          <IconButton onClick={() => window.open(file)} className={classes.download} size="large">
            <FileDownloadIcon fontSize="large" />
          </IconButton>
        </Tooltip>
      </Box>
      <Paper
        elevation={6}
        style={{
          transform: album ? `rotate(-0.25turn) translate(-${albumTranslation}px, -${albumTranslation}px)` : 'none',
        }}
      >
        <Box
          height={album ? documentWidth : documentHeight}
          width={album ? documentHeight : documentWidth}
          marginTop={theme.spacing(2.5)}
          className={showBorders}
          overflow="hidden"
        >
          {/*@ts-ignore*/}
          <Document file={file} onLoadSuccess={onDocumentLoad} onLoadError={console.error} className={classes.document}>
            {isValidPercievedPage
              ? Array.from(Array(pages_per_sheet).keys()).map((index) => {
                  return (
                    <Box key={index} height={pageHeight} width={pageWidth} className={showBorders}>
                      {/*@ts-ignore*/}
                      <Page
                        pageNumber={(pageNumber - 1) * pages_per_sheet + index + 1}
                        height={pageHeight}
                        className={showBorders}
                        error={<Box zIndex={2} width={pageWidth} height={pageHeight} bgcolor="white" />}
                      />
                    </Box>
                  );
                })
              : ''}
          </Document>
        </Box>
      </Paper>
    </Box>
  );
}
