import { Menu, MenuItem } from '@mui/material';
import { withStyles } from 'tss-react/mui';

// Casual dropdown menu
export const StyledMenu = withStyles(Menu, (theme) => ({
  paper: {
    border: '1px solid #d3d4d5',
    marginLeft: theme.spacing(2.5),
    minWidth: theme.spacing(20),
  },
}));
// Version for mobile devices
export const MobileStyledMenu = withStyles(Menu, (theme) => ({
  paper: {
    border: '1px solid #d3d4d5',
    marginLeft: theme.spacing(-2.5),
    minWidth: theme.spacing(30),
  },
}));

export const StyledMenuItem = withStyles(MenuItem, (theme) => ({
  root: {
    '&:focus': {
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}));
