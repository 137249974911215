/**
 * This file defines global website context object.
 * @packageDocumentation
 */
import React from 'react';

/** This is UserContext which is used exstensively by website components */
export type WebsiteContextType = {
  useIconsForPrinterDescriptions: boolean; // display icons or textual descriptions
};

/** Default value to initialize user context in app.tsx */
export const websiteContext: WebsiteContextType = {
  useIconsForPrinterDescriptions: false,
};

/** Context initializator */
export const WebsiteContext = React.createContext({
  ...websiteContext,
  updateContext: (newData: Partial<WebsiteContextType>) => {},
});

export default WebsiteContext;
