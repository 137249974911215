import { UserContextType } from 'Context';
import { isArrayPrinterData, PrinterStatus, Payment, FileStatus } from 'Interfaces';
import React from 'react';
import io from 'socket.io-client';
import SocketContext, { socketContext, SocketContextType } from './SocketContext/SocketContext';
import UserContext from './UserContext/UserContext';

interface PrinterStateUpdate {
  id: string; // printer id
  status: PrinterStatus;
  isStopped: boolean;
}

export default function SocketContextLoader(props: any) {
  const { name, setContext: setUserContext, updateContext: updateUserContext } = React.useContext(UserContext);
  const [context, setContext] = React.useState<SocketContextType>(socketContext);
  const [loaded, setLoaded] = React.useState(false);

  /** Updates socket context using shallow merge of UserContext attributes. */
  const updateContext = (newData: Partial<SocketContextType>) => setContext((data) => ({ ...data, ...newData }));

  React.useEffect(() => {
    if (!name) {
      // Skip loading sockets
      setLoaded(true);
      return;
    }

    const socket = io({ path: '/ws', query: { user_name: name } });
    socket.on('load', (arg) => {
      if (!isArrayPrinterData(arg)) {
        console.log('Socket type guard error');
      }
      updateContext({ printers: arg });
    });

    socket.on('printer state update', (data: PrinterStateUpdate) => {
      setContext((context) => {
        const { printers } = context;
        const { id, status, isStopped } = data;
        const index = printers.findIndex((printer) => printer.id === id);
        if (index === -1) {
          // Context is not up to date. Printer id was changed in the database.
          document.location.reload();
          return context;
        }

        // Updating printer state
        printers[index].status = status;
        printers[index].isStopped = isStopped;
        updateContext({ printers });
        return context;
      });
    });

    socket.on('job state update', (data: { system_name: string; state: FileStatus }) => {
      setUserContext((user_context: UserContextType) => {
        const { files } = user_context;
        const new_files = files.map((file) => {
          if (!(file.system_name === data.system_name)) return file;
          file.status = data.state;
          return file;
        });
        updateUserContext({ files: new_files });
        return user_context;
      });
    });

    socket.on('printer paper change', (data: { printer_id: string; paper: number }) => {
      setContext((context) => {
        const { printers } = context;
        const index = printers.findIndex((printer) => printer.id === data.printer_id);
        if (index === -1) {
          // Context is not up to date. Printer id was changed in the database.
          document.location.reload();
          return context;
        }
        context.printers[index].paper = data.paper;
        return context;
      });
    });

    socket.on('finish print', (data: { system_name: string; balance: string; reserved: string; state: FileStatus }) => {
      setUserContext((user_context) => {
        const { files } = user_context;
        const new_files = files.map((file) => {
          if (!(file.system_name === data.system_name)) return file;
          file.status = data.state;
          return file;
        });
        updateUserContext({ files: new_files, balance: data.balance, reserved: data.reserved });
        return user_context;
      });
    });

    socket.on('user balance change', (data: { balance: string; payments: Payment[] }) => {
      setUserContext((user_context) => {
        console.log('User balance change', data.balance, data.payments);
        updateUserContext({ balance: data.balance, payments: data.payments });
        return user_context;
      });
    });

    setLoaded(true);

    return () => {
      socket.disconnect();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  return (
    <SocketContext.Provider value={{ ...context, updateContext }}>{loaded && props.children}</SocketContext.Provider>
  );
}
