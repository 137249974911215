/**
 * Functions to calculate printjob price
 * @packageDocumentation
 */
import { Decimal } from 'decimal.js';

import { TonerPrice, PrintSettings, Fill } from 'Interfaces';

// returns a string representation of the decimal document price
export default function calculatePrice(
  printSettings: PrintSettings,
  fill: Fill,
  toner_price: TonerPrice | undefined,
): string {
  if (!toner_price) return '';
  const { copies, sides, print_color_mode, pages_per_sheet } = printSettings;
  const { cmyk, grayscale, pages } = fill;

  // Number of real papers used for printing
  const paper_quantity =
    sides === 'one-sided' ? Math.ceil(pages / pages_per_sheet) : Math.ceil(pages / (2 * pages_per_sheet));
  const paper_price = print_color_mode === 'monochrome' ? 3.5 : 6;
  const total_paper_price = paper_quantity * paper_price;

  // Total toner cost
  let toner_price_per_page =
    print_color_mode === 'monochrome'
      ? toner_price.black * grayscale
      : toner_price.cyan * cmyk.C +
        toner_price.magenta * cmyk.M +
        toner_price.yellow * cmyk.Y +
        toner_price.black * cmyk.K;

  const total_fill = print_color_mode === 'monochrome' ? grayscale : cmyk.C + cmyk.M + cmyk.Y + cmyk.K;
  if (total_fill >= 30 && print_color_mode === 'monochrome') toner_price_per_page = 1.5;
  if (total_fill >= 30 && print_color_mode !== 'monochrome') toner_price_per_page = 2;

  const total_toner_price = (toner_price_per_page * pages) / pages_per_sheet;

  const totalCost = (total_paper_price + total_toner_price) * copies;
  return new Decimal(totalCost).toFixed(2);
}
