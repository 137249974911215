import { useTheme } from '@mui/material';

import { FileStatus } from 'Interfaces';

// Hook to draw file statuses
export default function useFileStatus(status: FileStatus) {
  const theme = useTheme();
  const UserFileStatus: Record<FileStatus, { color: string; value: string }> = {
    uploaded: { value: 'Загружен', color: theme.palette.text.primary },
    queued: { value: 'В очереди', color: 'rgb(80, 80, 80)' },
    'settings saved': { value: 'Настройки сохранены', color: 'rgba(230, 116, 30, 90)' },
    printing: { value: 'Печатается', color: 'springgreen' },
    printed: { value: 'Напечатан', color: 'green' },
    'error encountered': { value: 'Возникла ошибка', color: 'orange' },
    'print error': { value: 'Ошибка печати', color: 'red' },
  };
  return UserFileStatus[status];
}

// Priorities for the file table sorting
// Lower level means higher priority
export const FileStatusPriority: Record<FileStatus, number> = {
  printing: 0,
  queued: 1,
  'settings saved': 2,
  uploaded: 3,
  printed: 4,
  'error encountered': 4,
  'print error': 4,
};
