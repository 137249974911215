/**
 * Page for printing. Is displayed only if activeFile exists
 * @packageDocumentation
 */
import React, { useContext } from 'react';
import { Box, Paper, Grid, useTheme } from '@mui/material';
import { Navigate } from 'react-router-dom';

import { NavigationBar } from 'Components';
import Preview from './PrintPage/Preview';
import PrintSettingsForm from './PrintPage/PrintForm';
import { UserContext } from 'Context';

/** Main part of the page */
export default function PrintPage() {
  const theme = useTheme();
  // If no active file is chosen redirects to files page.
  const { activeFile } = useContext(UserContext);
  return (
    <Box width="100vw" height="100vh" overflow="hidden">
      <NavigationBar tabIndex={0} />
      {activeFile ? (
        <Box
          position="absolute"
          top={theme.spacing(8)}
          height={`calc(100vh - ${theme.spacing(8)})`}
          width="100vw"
          overflow="hidden"
          bgcolor="background"
        >
          <Paper elevation={6}>
            <Box width="100vw" overflow="auto" height={`calc(100vh - ${theme.spacing(8)})`}>
              <Grid container>
                <Grid item xs={12} md={6}>
                  <PrintSettingsForm activeFile={activeFile} />
                </Grid>
                <Grid item xs={12} md={6}>
                  <Preview activeFile={activeFile} />
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Box>
      ) : (
        <Navigate to="/" />
      )}
    </Box>
  );
}
