/**
 * Form to manage the number of copies, color mode and one/two-sided prinitng mode.
 * @packageDocumentation
 */

import { Box } from '@mui/material';
import ColorModeField from './PrintSettingsForm/ColorModeField';
import CopiesField from './PrintSettingsForm/CopiesField';
import PagesPerSheet from './PrintSettingsForm/PagesPerSheet';
import { PrintFormAction } from '../shared/useFormManager';
import { PrintSettings } from 'Interfaces';
import React from 'react';
import SidesField from './PrintSettingsForm/SidesField';
import { makeStyles } from 'tss-react/mui';
import { mdWidth } from 'Themes';

type Props = {
  printSettings: PrintSettings;
  dispatch: React.Dispatch<PrintFormAction>;
};

const useStyles = makeStyles()((theme) => ({
  field: {
    marginLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  [`@media (max-width: ${mdWidth}px)`]: {
    field: {
      marginLeft: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
  },
}));

/** Component to manage printSettings of the active file. Namely: color mode, sides and number of copies. */
export default function PrintSettingsForm({ printSettings, dispatch }: Props) {
  const { classes } = useStyles();

  // Color mode handler
  const setColorMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: 'change_print_settings',
      print_settings: { ...printSettings, print_color_mode: (event.target as HTMLInputElement).value },
    });
  };

  // Copies field handler
  const setCopies = (copies: number) => {
    dispatch({
      type: 'change_print_settings',
      print_settings: { ...printSettings, copies },
    });
  };

  // Sides field handler
  const setSides = (value: string) => {
    dispatch({
      type: 'change_print_settings',
      print_settings: {
        ...printSettings,
        sides: value,
      },
    });
  };

  // Borders handler
  const setBorders = (borders: boolean) => {
    dispatch({
      type: 'change_print_settings',
      print_settings: {
        ...printSettings,
        borders,
      },
    });
  };

  // Album handler
  const setAlbum = (album: boolean) => {
    dispatch({
      type: 'change_print_settings',
      print_settings: {
        ...printSettings,
        album,
      },
    });
  };

  return (
    <>
      <Box width="100%" display="flex" flexWrap="wrap">
        <Box width="100%" className={classes.field}>
          <ColorModeField print_color_mode={printSettings.print_color_mode} onChange={setColorMode} />
        </Box>
        <Box width="100%" className={classes.field}>
          <CopiesField copies={printSettings.copies} setCopies={setCopies} />
        </Box>
        <Box width="100%" className={classes.field}>
          <SidesField sides={printSettings.sides} onChange={setSides} />
        </Box>
        <Box width="100%" className={classes.field}>
          <PagesPerSheet setBorders={setBorders} setAlbum={setAlbum} dispatch={dispatch} />
        </Box>
      </Box>
    </>
  );
}
