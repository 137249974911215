/**
 * Checks if the current printer capabilities match print settings.
 * @packageDocumentation
 */
import Decimal from 'decimal.js';

import { PrinterData, PrintSettings } from 'Interfaces';
import readablePrinterStatus from './readablePrinterStatus';

// Checks if the printer is suitable to print with current print settings. Returns '' if the printer is good, error_message overwise.
export default function matchPrinter(printer: PrinterData, print_settings: PrintSettings, pages: number): string {
  // Printer is ready to accespt jobs
  let error_message = '';
  let color_match = false;
  let sides_match = false;
  let paper_match = false;
  const readableStatus = readablePrinterStatus(printer.status, printer.isStopped);
  // Checking color
  if (['Свободен', 'Печатает'].includes(readableStatus)) {
    if (print_settings.print_color_mode === 'color') {
      if (printer.print_capabilities.color) {
        color_match = true;
      } else {
        error_message = 'Ч/Б принтер';
      }
    } else {
      if (!printer.print_capabilities.color) {
        color_match = true;
      } else {
        error_message = 'Цветной принтер';
      }
    }
    // Checking sides
    if (print_settings.sides !== 'one-sided') {
      if (printer.print_capabilities.two_sided) {
        sides_match = true;
      } else {
        error_message = 'Не поддерживает двустороннюю печать';
      }
    } else {
      sides_match = true;
    }

    // Checking paper
    const paper = print_settings.sides === 'one-sided' ? pages : Math.ceil(pages / 2);
    const totalPaper = paper * print_settings.copies;

    paper_match = new Decimal(totalPaper).lessThanOrEqualTo(new Decimal(printer.paper));
    if (!paper_match) {
      error_message = 'Недостаточно бумаги';
    }

    //  If printer matches settings return
    if (color_match && sides_match && paper_match) return '';
    return error_message;
  } else {
    return readableStatus;
  }
}
