import React from 'react';
import { Tooltip, Typography, SvgIcon } from '@mui/material';

import { ReactComponent as ColorIcon } from 'Assets/color.svg';
import { ReactComponent as MonochromeIcon } from 'Assets/monochrome.svg';

// Displays printer color mode: either color or grayscale printing
export default function ColorMode({ color, useIcons }: { color: boolean; useIcons: boolean }) {
  return (
    <>
      {useIcons ? (
        <Tooltip title={<Typography variant="body1">{color ? 'Цветной' : 'Ч/Б'}</Typography>}>
          <SvgIcon>{color ? <ColorIcon /> : <MonochromeIcon />}</SvgIcon>
        </Tooltip>
      ) : (
        <Typography variant="body2" color="inherit">
          {color ? 'Цветной' : 'Ч/Б'}
        </Typography>
      )}
    </>
  );
}
