import { Box, Typography, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import React from 'react';

const useStyles = makeStyles()((theme) => ({
  footerItem: { marginLeft: theme.spacing(2.5) },
  footerLink: {
    textDecoration: 'none',
    fontWeight: 'lighter',
    '&:hover, &:visited': { color: theme.palette.text.primary },
  },
  contact: {
    marginLeft: 'auto',
    marginRight: theme.spacing(2.5),
  },
}));
export default function Footer() {
  const theme = useTheme();
  const { classes } = useStyles();
  return (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      bgcolor="rgb(230,230,230)"
      height={theme.spacing(4)}
      width="100vw"
      display="flex"
      alignItems="center"
    >
      <Typography variant="body2" color="textPrimary" className={classes.footerItem}>
        <a href="/payments_info_v3" className={classes.footerLink}>
          Об оплате
        </a>
      </Typography>
      <Typography variant="body2" className={classes.contact}>
        info@printmipt.ru
      </Typography>
    </Box>
  );
}
