import { PrinterStatus } from 'Interfaces';


enum ReadableStatus {
  'idle' = 'Свободен',
  'processing' = 'Печатает',
  // printing related errors
  'socket_error' = 'Нет связи',
  'con_error' = 'Нет связи',
  'con_buffer_overflow' = 'Не работает',
  'con_timeout' = 'Свободен', // probably is not used
  'con_lost' = 'Потеря соединения',
  'paper_jam' = 'Зажев бумаги',
  'open_cover' = 'Открыта крышка',
  'load_cassette' = 'Закончилась бумага',
  'cancelling_data' = 'Не работает',
  'toner_low' = 'Закончился тонер',
  'need_power_cycle' = 'Требуется перезагрузка',
  'unknown' = 'Неизвестная ошибка', // status for unknown pjl codes;
}

export default function readablePrinterStatus(status: PrinterStatus, isStopped: boolean): string {
  if (isStopped) return 'Остановлен Администратором';
  return ReadableStatus[status];
}
