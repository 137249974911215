import { Box, Grid, Paper, Typography } from '@mui/material';

import React from 'react';
import map from 'Assets/set1.svg';
import settings from 'Assets/set4.svg';
import time from 'Assets/time1.svg';

export default function Banner() {
  return (
    <Paper elevation={3}>
      <Box
        width="100%"
        maxWidth="100%"
        minHeight={500}
        bgcolor="rgb(240, 240, 237)"
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Box maxWidth={1200} /*bgcolor="rgba(0, 75, 160, 0.1)"*/>
          <Grid container spacing={0}>
            <Grid item xs={12} md={4}>
              <Box padding={1.5} textAlign="center" color="rgba(0, 75, 160, 100)">
                <img src={time} alt="" width="100px" />
                <Typography variant="h5">Экономим время</Typography>
              </Box>

              <Box padding={1.5} textAlign="center" color="rgb(60, 60, 60)">
                <Typography variant="body1">
                  22 притера по всему кампусу, стоимость от 2.5 р, печатаем за 30 сек
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box padding={1.5} textAlign="center" color="rgba(0, 75, 160, 100)">
                <img src={settings} alt="" width="100px" />
                <Typography variant="h5">Расширяем возможности</Typography>
              </Box>

              <Box padding={1.5} textAlign="center" color="rgb(60, 60, 60)">
                <Typography variant="body1">Мини-шпаргалки, настройки документа перед печатью, предпросмотр</Typography>
              </Box>
            </Grid>
            <Grid item xs={12} md={4}>
              <Box padding={1.5} textAlign="center" color="rgba(0, 75, 160, 100)">
                <img src={map} alt="" width="100px" />
                <Typography variant="h5">Упрощаем печать </Typography>
              </Box>

              <Box padding={1.5} textAlign="center" color="rgb(60, 60, 60)">
                <Typography variant="body1">Поддерживаем 20 форматов документов, цветные и чб принтеры</Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Paper>
  );
}
