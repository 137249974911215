/**
 * Interfaces for server responses.
 * @packageDocumentation
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types  */
import { OauthHandles } from 'Context/UserContext/UserContext';
import { Decimal } from 'decimal.js';

// Data interfaces
// ----------------------------------------------------------------------------------

// Rounds to 0.01 rouble
export const Decimal2 = Decimal.clone({ rounding: 2 });

// Sides from ipp. For the print settings.
export const SIDES = ['one-sided', 'two-sided-long-edge', 'two-sided-short-edge'];
export type Sides = (typeof SIDES)[number];

// Print color mode property from ipp.
export const PRINT_COLOR_MODE = ['color', 'monochrome'];
export type PrintColorMode = (typeof PRINT_COLOR_MODE)[number];

export const FILE_STATUS = [
  'uploaded',
  'settings saved',
  'queued',
  'printing',
  'printed',
  'error encountered',
  'print error',
];

export type FileStatus = (typeof FILE_STATUS)[number];

export const PAGES_PER_SHEET = [1, 2, 4, 8, 9, 16, 18];
export type PagesPerSheet = (typeof PAGES_PER_SHEET)[number];

/** Printer settings */
export interface PrintSettings {
  printer_id?: string /** printer_id */;
  copies: number /** number of copies */;
  sides: Sides /** sides */;
  print_color_mode: PrintColorMode /** color mode */;
  pages_per_sheet: PagesPerSheet;
  borders: boolean;
  album?: boolean; // album orientation for pages per sheet grid layouts
}

// Printjob fill values for price calculation
export interface Fill {
  cmyk: CMYK; // Fill percent in CMYK color-space
  grayscale: number; // Fill percent in grayscale color-space.
  pages: number; // Number of pages
}

// Ink fill in CMYK color space
export type CMYK = {
  C: number; // Cyan
  M: number; // Magenta
  Y: number; // Yellow
  K: number; // Black
};

/** Toner price for the printer */
export type TonerPrice = {
  cyan: number;
  magenta: number;
  yellow: number;
  black: number;
};

/** File data is returned it this format from server. */
export type FileData = {
  name: string /** user defined name */;
  system_name: string /** system file nam, unique */;
  date: Date /** last access date */;
  print_settings: PrintSettings /** settings for the printer */;
  fill: Fill /** ink coverage for the price calculation */;
  status: FileStatus /** file status */;
};

/** Printer location */
export type PrinterLocation = {
  description?: string /** human-readable description */;
  address?: string /** printer address */;
  /** geographic coordinates */
  coordinates?: {
    latitude: number;
    longitude: number;
  };
};

/** printer capabilities */
export type PrintCapabilities = {
  color: boolean;
  two_sided: boolean;
  copies: boolean;
};

// Printer statuses recognised by the server
export type PrinterStatus =
  | 'idle'
  | 'processing'
  // printing related errors
  | 'socket_error'
  | 'con_error'
  | 'con_buffer_overflow'
  | 'con_timeout' // probably is not used
  | 'con_lost'
  | 'paper_jam'
  | 'open_cover'
  | 'load_cassette'
  | 'cancelling_data'
  | 'toner_low'
  | 'need_power_cycle'
  | 'unknown'; // status for unknown pjl codes;

export type SimplePrinterStatus = 'ready' | 'processing' | 'stopped';

/** Printer data. */
export type PrinterData = {
  id: string /** unique printers id */;
  name: string /** printers name */;
  status: PrinterStatus;
  isStopped: boolean;
  paperMax: number /** max paper in a tray, all of the printers currently operate with single tray */;
  paper: number /** current paper in a printer tray */;
  location?: PrinterLocation /** printer location */;
  print_capabilities: PrintCapabilities /** printer capabilities */;
  toner_price: TonerPrice /** toner price */;
};

// Authentication interfaces
// ----------------------------------------------------------------------------------

/**  Login form values. */
export type UserLogin = {
  name: string /** User name. Not longer than 30 characters.*/;
  password: string /** user password */;
};

/** Registration form values */
export type UserRegistration = UserLogin & {
  email: string /** email */;
};

// Response to the load() method called on each page refresh.
export type LoadResponse = {
  _id: string;
  name: string /** user name */;
  files: FileData[] /** files */;
  favouritePrinters: string[] /** favourite printers array */;
  balance: string /** user balance, rounded to 2 floating points decimal string representation */;
  reserved: string;
  news: News[];
  payments: Payment[];
  defaultPrinter?: string;
  oauth?: OauthHandles;
};

// Response for the registration form
export type RegisterResponse = {
  _id: string;
  name: string /** user name */;
  token: string /** json web token */;
  balance: string /** user balance, rounded to 2 floating points decimal string representation */;
  reserved: string;
  oauth?: OauthHandles;
  news: News[];
};

/** Response from the server to the login form. */
export type LoginResponse = LoadResponse & {
  token: string /** json web token */;
};

// News from the server
export type News = {
  date: string /** news publication date */;
  title: string /** news title */;
  text: string /** news text */;
  image: string /** news image */;
  url?: string /** news url relative to the /news path */;
};

export interface Payment {
  id: string;
  sum: string;
  ps_id: number;
  fop_receipt_key?: string;
  card_number?: string;
  date: string;
}

// Type Guards
// ----------------------------------------------------------------------------------

const isDecimal = (value: any): value is Decimal => {
  try {
    new Decimal(value);
    return true;
  } catch {
    return false;
  }
};

/** Checks if item is a valid date */
const isValidDate = (value: any): value is Date => {
  return value instanceof Date && !isNaN(value as any);
};

/** Type Guard for Sides */
const isSides = (value: any): value is Sides => {
  return SIDES.includes(value as Sides);
};

/** Type Guard for PrintColorMode */
const isPrintColorMode = (value: any): value is PrintColorMode => {
  return PRINT_COLOR_MODE.includes(value as PrintColorMode);
};

/** Type guard for the file status */
const isFileStatus = (value: any): value is FileStatus => {
  return FILE_STATUS.includes(value as FileStatus);
};

/** Type Guard for pages per sheet */
const isPagesPerSheet = (value: any): value is PagesPerSheet => {
  return PAGES_PER_SHEET.includes(value as PagesPerSheet);
};

/** Type Guard for PrintSettings */
export function isPrintSettings(item: any): item is PrintSettings {
  return (
    typeof item.copies === 'number' &&
    isSides(item.sides) &&
    isPrintColorMode(item.print_color_mode) &&
    isPagesPerSheet(item.pages_per_sheet)
  );
}

/** Type Guard for CMYK */
export function isCMYK(value: any): value is CMYK {
  return (
    typeof value.C === 'number' &&
    typeof value.M === 'number' &&
    typeof value.Y === 'number' &&
    typeof value.K === 'number'
  );
}

/** Type guard for fill */
export function isFill(value: any): value is Fill {
  return typeof value.grayscale === 'number' && typeof value.pages === 'number' && isCMYK(value.cmyk);
}
/** Type Guard for FileData */
export function isFileData(item: any): item is FileData {
  return (
    typeof item.name === 'string' &&
    typeof item.system_name === 'string' &&
    isValidDate(new Date(item.date)) &&
    isPrintSettings(item.print_settings) &&
    isFill(item.fill) &&
    isFileStatus(item.status)
  );
}

/** Type Guard for PrintCapabilities */
export function isPrintCapabilities(item: any): item is PrintCapabilities {
  return typeof item.copies === 'boolean' && typeof item.two_sided === 'boolean' && typeof item.color === 'boolean';
}

/** Type guard for toner_price */
export function isTonerPrice(item: any): item is TonerPrice {
  return (
    typeof item.cyan === 'number' &&
    typeof item.magenta === 'number' &&
    typeof item.yellow === 'number' &&
    typeof item.black === 'number'
  );
}

/** Type Guard for PrinterData */
export function isPrinterData(item: any): item is PrinterData {
  return (
    typeof item.id === 'string' &&
    typeof item.name === 'string' &&
    typeof item.status === 'string' &&
    typeof item.isStopped === 'boolean' &&
    typeof item.paper === 'number' &&
    typeof item.paperMax == 'number' &&
    // Printer Location is optional
    item.print_capabilities &&
    isPrintCapabilities(item.print_capabilities) &&
    item.toner_price &&
    isTonerPrice(item.toner_price)
  );
}

/** Type Guard for PrinterData[] */
export function isArrayPrinterData(value: any): value is PrinterData[] {
  return Array.isArray(value) && value.every((item) => isPrinterData(item));
}

/** Type Guard for Array<FileData> */
export function isArrayFileData(value: any): value is FileData[] {
  return Array.isArray(value) && (value.length === 0 || value.every((item) => isFileData(item)));
}

/** Type guard for News */
export function isNews(item: any): item is News {
  return (
    typeof item.date === 'string' &&
    typeof item.title === 'string' &&
    typeof item.text === 'string' &&
    typeof item.image === 'string'
  );
}

/** Type guard for the News Array */
export function isNewsArray(value: any): value is News[] {
  return Array.isArray(value) && value.every((item) => isNews(item));
}

export function isPayment(item: any): item is Payment {
  if (!item) return true;
  return (
    typeof item.id === 'string' &&
    typeof item.sum === 'string' &&
    typeof item.ps_id === 'string' &&
    typeof item.date === 'string'
  );
}

export function isPaymentArray(value: any): value is Payment[] {
  return Array.isArray(value) && value.every((item) => isPayment(item));
}

/** Type Guard for LoadResponse */
export function isLoadResponse(item: any): item is LoadResponse {
  return (
    typeof item._id === 'string' &&
    typeof item.name === 'string' &&
    isArrayFileData(item.files) &&
    Array.isArray(item.favouritePrinters) &&
    item.favouritePrinters.every((value: any) => typeof value === 'string') &&
    isDecimal(item.balance) &&
    isDecimal(item.reserved) &&
    isNewsArray(item.news) &&
    isPaymentArray(item.payments)
  );
}

/** Type Guard for the registration response */
export function isRegisterResponse(item: any): item is RegisterResponse {
  return (
    typeof item._id === 'string' &&
    typeof item.name === 'string' &&
    typeof item.token === 'string' &&
    isDecimal(item.balance) &&
    isDecimal(item.reserved) &&
    isNewsArray(item.news)
  );
}

/** TypeGuard for UserResponse */
export function isLoginResponse(item: any): item is LoginResponse {
  return typeof item.token === 'string' && isLoadResponse(item);
}
