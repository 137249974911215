import React from 'react';
import { TableRow, TableCell, Tooltip, Typography, IconButton, Hidden } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';

import { FileData } from 'Interfaces';
import { parsed_date } from 'Utils';
import useFileStatus from '../shared/useFileStatus';
import { mdWidth } from 'Themes';

const useStyles = makeStyles()((theme) => ({
  buttonCol: {
    width: theme.spacing(5),
  },
  tableCell: {
    padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
    cursor: 'pointer',
  },
  fileNamecol: {
    maxWidth: '30vw',
    overflow: 'auto',
  },
  [`@media (max-width: ${mdWidth}px)`]: {
    tableCell: {
      fontSize: '0.6em',
      cursor: 'pointer',
      padding: `0px ${theme.spacing(1)}`,
    },
  },
}));

// One row of the files table
export default function FileTableRow({
  file,
  printFile,
  deleteFile,
  printer,
}: {
  file: FileData;
  printer: string; // printer name
  printFile: (file: FileData) => void;
  deleteFile: (fileName: string) => Promise<void>;
}) {
  const { classes, cx } = useStyles();
  const status = useFileStatus(file.status);
  return (
    <TableRow key={file.system_name}>
      <Hidden mdDown>
        <TableCell className={cx(classes.tableCell)}>
          <Tooltip title={<Typography variant="body1">Настройки печати</Typography>}>
            <IconButton
              size="small"
              color="inherit"
              component="span"
              onClick={() => {
                printFile(file);
              }}
            >
              <PrintOutlinedIcon />
            </IconButton>
          </Tooltip>
        </TableCell>
      </Hidden>
      <TableCell className={cx(classes.tableCell, classes.fileNamecol)} onClick={() => printFile(file)}>
        {file.name}
      </TableCell>
      <TableCell className={classes.tableCell} onClick={() => printFile(file)} style={{ color: status.color }}>
        {status.value}
      </TableCell>
      <TableCell className={classes.tableCell} onClick={() => printFile(file)}>
        {printer}
      </TableCell>
      <TableCell align="center" className={classes.tableCell} onClick={() => printFile(file)}>
        {parsed_date(file.date)}
      </TableCell>

      <TableCell className={cx(classes.buttonCol, classes.tableCell)}>
        <Tooltip title={<Typography variant="body1">Удалить файл</Typography>}>
          <IconButton
            size="small"
            color="inherit"
            component="span"
            onClick={() => {
              deleteFile(file.system_name);
            }}
          >
            <DeleteOutlineIcon />
          </IconButton>
        </Tooltip>
      </TableCell>
    </TableRow>
  );
}
