import {
  AccountPage,
  FilesPage,
  LandingPage,
  LoginPage,
  NewsPage,
  PageWithHtml,
  PaymentPage,
  PrintPage,
  PrintersPage,
  PrintingHousePage,
  RegistrationPage,
  TestPage,
} from 'Pages';
import { Navigate, Route, Routes } from 'react-router-dom';

import React from 'react';
import { UserContext } from 'Context';

// Website router
export default function Router() {
  const { name } = React.useContext(UserContext);
  const loaded = name !== '';

  return (
    <Routes>
      <Route path="/test" element={<TestPage />} />
      <Route path="/landing" element={<LandingPage />} />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegistrationPage />} />
      <Route path="/payment" element={<PaymentPage />} />
      {/*<Route path="/passwordReset" element={<PasswordResetPage />} />*/}
      <Route path="/printers" element={loaded ? <PrintersPage /> : <Navigate to="/landing" />} />
      <Route path="/" element={loaded ? <FilesPage /> : <Navigate to="/landing" />} />
      <Route path="/print" element={loaded ? <PrintPage /> : <Navigate to="/landing" />} />
      {/*<Route path="/printingHouse" element={loaded ? <PrintingHousePage /> : <Navigate to="/landing" />} />*/}
      <Route path="/account" element={loaded ? <AccountPage tabIndex={2} /> : <Navigate to="/landing" />} />
      <Route path="/news" element={loaded ? <NewsPage /> : <Navigate to="/landing" />} />
      <Route path="/news/*" element={loaded ? <PageWithHtml tabIndex={3} /> : <Navigate to="/landing" />} />
      <Route path="/*" element={<PageWithHtml tabIndex={false} />} />
    </Routes>
  );
}
