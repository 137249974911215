/**
 * Table to show printers
 * @packageDocumentation
 */
import React, { useContext } from 'react';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { UserContext, SocketContext } from 'Context';
import PrintersTableRow from './PrintersTable/PrintersTableRow';
import { Alert, useAlert } from 'Components';
import { mdWidth } from 'Themes';
import { PrinterData } from 'Interfaces';
import simplifyPrinterStatus from 'Utils/simplifyPrinterStatus';
import getSpacing from 'Utils/getSpacing';
import { getWindowDimensions } from 'Utils/useWindowDimensions';

const useStyle = makeStyles()((theme) => ({
  tableCell: {},
  [`@media (max-width: ${mdWidth}px)`]: {
    tableCell: {
      padding: `0px ${theme.spacing(1)}`,
    },
  },
}));

export default function PrintersTable({ useIcons }: { useIcons: boolean }) {
  const { favouritePrinters } = useContext(UserContext);
  const { printers } = useContext(SocketContext);
  const { width } = getWindowDimensions();
  const theme = useTheme();
  const { classes } = useStyle();

  // Alert message
  const { alertState, setAlertState } = useAlert();
  // Method to handle snackbar close
  const handleAlertClose = () => setAlertState({ isOpened: false });

  const rows = printers.map((printer: PrinterData) => {
    const favourite = favouritePrinters.includes(printer.id);
    return { printer, favourite };
  });

  rows.sort((a, b) => {
    const a_state = simplifyPrinterStatus(a.printer.status, a.printer.isStopped);
    const b_state = simplifyPrinterStatus(b.printer.status, b.printer.isStopped);
    if (a.favourite === b.favourite) {
      if (a_state === 'stopped') {
        if (b_state === 'stopped') return 0;
        return 1;
      }
      if (b_state === 'stopped') return -1;
      const nameA = parseInt(a.printer.name);
      const nameB = parseInt(b.printer.name);
      if (isNaN(nameA)) return -1;
      if (isNaN(nameB)) return 1;
      return nameA >= nameB ? 1 : -1;
    }
    if (a.favourite) return -1;
    return 1;
  });

  return (
    <>
      <Box>
        <TableContainer
          sx={{
            overflow: 'auto',
            maxHeight: window.innerHeight - getSpacing(theme, 28),
            maxWidth: width > mdWidth ? width - getSpacing(theme, 5) : width,
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} />
                <TableCell className={classes.tableCell}>Принтер</TableCell>
                <TableCell className={classes.tableCell}>Расположение</TableCell>
                <TableCell className={classes.tableCell}>Цветная печать</TableCell>
                <TableCell className={classes.tableCell}>Бумага</TableCell>
                <TableCell className={classes.tableCell}>Состояние</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => {
                return (
                  <TableRow key={row.printer.id}>
                    <PrintersTableRow
                      printer={row.printer}
                      favourite={row.favourite}
                      setAlertState={setAlertState}
                      useIcons={useIcons}
                    />
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      <Alert autoHideDuration={6000} state={alertState} onClose={handleAlertClose} />
    </>
  );
}
