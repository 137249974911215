/**
 * Page with user files. Is displayed on '/' path
 * @packageDocumentation
 */
import React from 'react';
import { Box, useTheme } from '@mui/material';

import { Alert, Footer, NavigationBar, SimplePaymentForm, StyledCheckbox, useAlert } from 'Components';
import FileTable from './FilesPage/FileTable';
import FileUploadForm from './FilesPage/FileUploadForm';
import { FileData } from 'Interfaces';
import { UserContext } from 'Context';

import { getWindowDimensions } from 'Utils/useWindowDimensions';
import getSpacing from 'Utils/getSpacing';

/** Main part of the page */
export default function FilePage() {
  const theme = useTheme();
  const [file, setFile] = React.useState<string>('');
  const { updateContext } = React.useContext(UserContext);

  // Checkbox states
  const [showPrintQueue, setShowPrintQueue] = React.useState<boolean>(true);
  const [showPrintHistory, setShowPrintHistory] = React.useState<boolean>(true);

  // Alert message
  const { alertState, setAlertState } = useAlert();

  const handleAlertClose = (event?: React.SyntheticEvent, reason?: string) => setAlertState({ isOpened: false });

  const { width, height } = getWindowDimensions();

  return (
    <Box width={width} height={height} overflow="hidden">
      <NavigationBar tabIndex={0} />
      <Box width="100vw" height={theme.spacing(8)} position="fixed" top={theme.spacing(8)}>
        <FileUploadForm
          file={file}
          setFile={(v: string) => setFile(v)}
          setActiveFile={(file: FileData) => updateContext({ activeFile: file })}
        />
      </Box>
      <Box
        position="fixed"
        top={theme.spacing(16)}
        width="100vw"
        marginLeft={theme.spacing(2.5)}
        height={theme.spacing(4)}
        display="flex"
        alignItems="center"
      >
        <StyledCheckbox
          isChecked={showPrintQueue}
          label="Очередь печати"
          onChange={() => setShowPrintQueue(!showPrintQueue)}
        />
        <StyledCheckbox
          isChecked={showPrintHistory}
          label="История печати"
          onChange={() => setShowPrintHistory(!showPrintHistory)}
        />
      </Box>
      <Box
        position="fixed"
        top={theme.spacing(20)}
        width="100vw"
        height={height - getSpacing(theme, 24)}
        overflow="hidden"
      >
        <Box overflow="auto" maxHeight={height - getSpacing(theme, 25)} id="filesTable">
          <FileTable
            showPrintQueue={showPrintQueue}
            showPrintHistory={showPrintHistory}
            setActiveFile={(file: FileData) => updateContext({ activeFile: file })}
            setAlertState={setAlertState}
          />
        </Box>
        <Box marginLeft={theme.spacing(2.5)} height={theme.spacing(8)} display="flex" alignItems="center">
          <SimplePaymentForm />
        </Box>
      </Box>
      <Alert state={alertState} autoHideDuration={6000} onClose={handleAlertClose} />
      <Footer />
    </Box>
  );
}
