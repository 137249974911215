import { Box, Button, Collapse, Typography, useTheme } from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import React from 'react';

type Props = {
  header: string;
  text: JSX.Element;
  number: number;
  opened: boolean;
  setOpenedNumber(number): void;
};

export default function Dropdown({ header, text, number, setOpenedNumber, opened }: Props) {
  const theme = useTheme();

  const handleClick = () => {
    setOpenedNumber(number);
  };
  return (
    <Box width="100%" display="flex" flexDirection="column" textAlign="left">
      <div style={{ backgroundColor: 'background.default' }}>
        <Button
          variant="text"
          onClick={handleClick}
          sx={{ width: '100%', justifyContent: 'flex-start', textTransform: 'none', marginTop: theme.spacing(1) }}
        >
          {opened ? <KeyboardArrowDownIcon fontSize="large" /> : <KeyboardArrowRightIcon fontSize="large" />}
          <Typography variant="h5" color="textPrimary">
            {header}
          </Typography>
        </Button>
      </div>
      <div style={{ padding: '3px', backgroundColor: 'rgba(0, 75, 160, 0.1)' }}>
        <Collapse
          in={opened}
          collapsedSize={0}
          timeout={50}
          sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(1), marginLeft: theme.spacing(4) }}
        >
          {text}
        </Collapse>
      </div>
    </Box>
  );
}
