/**
 * Main theme for the website
 * @packageDocumentation
 */
import { createTheme, responsiveFontSizes } from '@mui/material';

const LightTheme = responsiveFontSizes(
  createTheme({
    palette: {
      primary: {
        main: 'rgba(0, 75, 160, 100)',
        light: 'rgba(0, 75, 160, 0.1)',
        contrastText: 'rgb(240, 240, 240)',
      },
      secondary: {
        main: 'rgb(160, 61, 0)',
      },
      info: {
        main: 'rgb(200, 200, 200)',
      },
      text: {
        primary: 'rgb(60, 60, 60)',
        secondary: 'rgb(240, 240, 240)',
      },
      background: {
        default: 'rgb(240, 240, 237)',
      },
    },
    typography: {
      fontSize: 16,
      h5: {
        '@media (max-width:960px)': {
          fontSize: '1rem',
        },
      },
      body1: {
        '@media (max-width:960px)': {
          fontSize: '0.85rem',
        },
      },
      body2: {
        '@media (max-width:960px)': {
          fontSize: '0.7rem',
        },
      },
    },
    spacing: 8,
  }),
);

export default LightTheme;
