/**
 * News grid for the page
 * @packageDocumentation
 */
import React from 'react';
import { Box } from '@mui/material';

import NewsCard from './NewsGrid/NewsCard';
import { UserContext } from 'Context';

export default function NewsGrid() {
  const { news } = React.useContext(UserContext);
  // Calculate grid spacing based on available width
  const availableWidth = window.innerWidth;
  // Card width is 300 px
  const cardWidth = 300;
  const min_space = availableWidth >= 340 ? 20 : 0;
  let number_of_cards = Math.floor((availableWidth - min_space) / (cardWidth + min_space));
  number_of_cards = Math.max(number_of_cards, 1);

  // Two spacings for each card- left and right
  let space = (availableWidth - cardWidth * number_of_cards) / (number_of_cards + 1);
  space = Math.max(space, 0);

  const sorted_news = news.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

  return (
    <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
      {sorted_news.map((item: any, index: any) => (
        <Box marginLeft={`${space}px`} key={index}>
          <NewsCard {...item} />
        </Box>
      ))}
    </Box>
  );
}
