/**
 * Page to display account actions
 * @packageDocumentation
 */
import React from 'react';
import { Box, Button, Typography, useTheme } from '@mui/material';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import PaymentsTable from './AccountPage/PaymentsTable';

import { NavigationBar, PrinterStateiconsCheckbox, Footer, useAlert, Alert } from 'Components';
import { UserContext, userContext } from 'Context';
import SimplePaymentForm from 'Components/SimplePaymentForm';
import { getWindowDimensions } from 'Utils/useWindowDimensions';
import getSpacing from 'Utils/getSpacing';
import BindAccount from './AccountPage/BindAccount';

export default function AccountPage({ tabIndex }: { tabIndex: number | false }) {
  const theme = useTheme();
  const { name, balance, updateContext } = React.useContext(UserContext);

  // Alert message
  const { alertState, setAlertState } = useAlert();

  const handleAlertClose = (event?: React.SyntheticEvent, reason?: string) => setAlertState({ isOpened: false });

  const Logout = () => {
    updateContext(userContext);
    document.cookie = 'token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
  };

  const { width, height } = getWindowDimensions();

  return (
    <Box width={width} height={height} overflow="hidden">
      <NavigationBar tabIndex={tabIndex} />
      <Box
        position="absolute"
        top={theme.spacing(8)}
        height={height - getSpacing(theme, 12)}
        width="100%"
        bgcolor="background"
        overflow="auto"
      >
        <Box marginLeft={theme.spacing(2.5)}>
          <Box marginY={theme.spacing(2.5)}>
            <Typography variant="h6" color="inherit">
              Пользователь: {name}
            </Typography>
            <Typography variant="h6" color="inherit">
              Баланс: {balance} р.
            </Typography>
            <SimplePaymentForm />
            <PaymentsTable />
          </Box>
          <BindAccount setAlertState={setAlertState} />

          <Box display="flex" marginBottom={theme.spacing(2.5)}>
            {/*<RefillButton />*/}
            <Box>
              <Button variant="contained" color="primary" onClick={Logout}>
                <ExitToAppIcon fontSize="small" />
                <Typography variant="body1" color="inherit">
                  Выйти
                </Typography>
              </Button>
            </Box>
          </Box>
          <hr />
          <br />
          <PrinterStateiconsCheckbox />
        </Box>
      </Box>
      <Alert onClose={handleAlertClose} autoHideDuration={10000} state={alertState} />
      <Footer />
    </Box>
  );
}
