/**
 * Printers management page.
 * @packageDocumentation
 */
import React from 'react';
import { Box, useTheme, Typography } from '@mui/material';
import { Alert, Footer, NavigationBar, PrinterStateiconsCheckbox, useAlert } from 'Components';
import PrintersTable from './PrintersPage/PrintersTable';
import { WebsiteContext } from 'Context';
import { mdWidth } from 'Themes';
import DefaultPrinterMenu from './PrintersPage/DefaultPrinterMenu';
import { getWindowDimensions } from 'Utils/useWindowDimensions';
import getSpacing from 'Utils/getSpacing';

/** Main part of the page */
export default function PrintersPage() {
  const theme = useTheme();
  const { useIconsForPrinterDescriptions: useIcons } = React.useContext(WebsiteContext);
  const { width, height } = getWindowDimensions();
  // Alert message
  const { alertState, setAlertState, handleAlertClose } = useAlert();

  return (
    <Box width={width} height={height} overflow="hidden">
      <NavigationBar tabIndex={1} />
      <Box
        position="fixed"
        top={theme.spacing(8)}
        height={theme.spacing(8)}
        display="flex"
        alignItems="center"
        overflow="hidden"
        marginLeft={theme.spacing(2.5)}
        marginBottom={theme.spacing(1)}
      >
        <Typography variant="body1">Принтер по умолчанию</Typography>
        <DefaultPrinterMenu setAlertState={setAlertState} />
      </Box>
      <Box
        position="fixed"
        top={theme.spacing(16)}
        maxHeight={height - getSpacing(theme, 24)}
        width="100vw"
        sx={{
          marginTop: theme.spacing(1),
          [`@media (min-width: ${mdWidth}px)`]: {
            table: {
              marginTop: theme.spacing(1),
              marginLeft: theme.spacing(2.5),
              marginRight: theme.spacing(2.5),
            },
          },
        }}
      >
        <PrintersTable useIcons={useIcons} />
        <Box height={theme.spacing(4)} marginLeft={theme.spacing(2.5)}>
          <PrinterStateiconsCheckbox />
        </Box>
      </Box>
      <Alert autoHideDuration={2000} state={alertState} onClose={handleAlertClose} />
      <Footer />
    </Box>
  );
}
