/**
 * Form to pick and display print settings
 * @packageDocumentation
 */

import { Alert, useAlert } from 'Components';
import { Box, Button, Typography } from '@mui/material';
import React, { useContext } from 'react';

import { FileData } from 'Interfaces';
import PrintButton from './PrintForm/PrintButton';
import PrintSettingsForm from './PrintForm/PrintSettingsForm';
import PrintersMenu from './PrintForm/PrintersMenuField';
import SaveSettingsButton from './PrintForm/SaveSettingsButton';
import { UserContext } from 'Context';
import { makeStyles } from 'tss-react/mui';
import { mdWidth } from 'Themes';
import useFormManager from './shared/useFormManager';

const useStyles = makeStyles()((theme) => ({
  header: {
    marginLeft: theme.spacing(5),
    marginTop: theme.spacing(5),
  },
  notEnough: {
    color: theme.palette.secondary.main,
  },
  enough: {
    color: 'inherit',
  },
  [`@media (max-width: ${mdWidth}px)`]: {
    header: {
      marginLeft: theme.spacing(2.5),
      marginTop: theme.spacing(2.5),
    },
  },
}));

// The main form for the file printing
export default function PrintForm({ activeFile }: { activeFile: FileData }) {
  const { classes } = useStyles();
  const { updateContext } = useContext(UserContext);

  // Form state management
  const [state, dispatch, context] = useFormManager();

  // States for alert
  const { alertState, setAlertState, handleAlertClose } = useAlert();

  // Validate form
  React.useEffect(() => {
    dispatch({ type: 'validate' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentPrinter, activeFile.print_settings, context.balance]);

  return (
    <>
      <Box width="100%" display="flex" className={classes.header}>
        {/*<Button variant="contained" color="primary" onClick={() => updateContext({ activeFile: null })}>
          Назад
  </Button>*/}
        <PrintersMenu
          dispatch={dispatch}
          printSettings={activeFile.print_settings}
          pages={activeFile.fill.pages}
          {...state}
        />
      </Box>

      <PrintSettingsForm dispatch={dispatch} printSettings={activeFile.print_settings} />

      <Box className={classes.header}>
        <Typography variant="h6" color="inherit">
          Стоимость печати:{' '}
          <span className={state.isEnoughMoney ? classes.enough : classes.notEnough}>{state.currentPrice}</span>{' '}
          {state.currentPrice ? 'руб.' : ''}
        </Typography>
      </Box>

      <Box width="100%" className={classes.header} display="flex">
        <SaveSettingsButton isValidPrinter={state.isValidPrinter} setAlertState={setAlertState} />
        <PrintButton dispatch={dispatch} setAlertState={setAlertState} {...state} />
      </Box>

      <Alert state={alertState} onClose={handleAlertClose} />
    </>
  );
}
