/**
 * Sides field of the print settings form. Allows to select on or two-sided printing.
 * @packageDocumentation
 */

import { FormControl, MenuItem, Select, Toolbar, Typography, useTheme } from '@mui/material';

import Box from '@mui/material/Box';
import React from 'react';

type Props = {
  sides: string;
  onChange(string): void;
};

export default function BasicSelect({ sides, onChange }: Props) {
  const theme = useTheme();
  return (
    <>
      <Toolbar
        sx={{
          display: { xs: 'flex' },
          flexDirection: 'row',
          justifyContent: 'left',
        }}
      >
        <Typography noWrap component="div" variant="h6" color="inherit">
          Стороны
        </Typography>

        <Box marginLeft={theme.spacing(2.5)} display="flex">
          <FormControl variant="outlined" margin="dense" sx={{ minWidth: 120, display: 'flex' }}>
            <Select id="sides" value={sides} label={''} onChange={(event) => onChange(event.target.value)}>
              {MenuLabels.map((item) => {
                return (
                  <MenuItem value={item.value} key={item.value}>
                    {item.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Box>
      </Toolbar>
    </>
  );
}

const MenuLabels = [
  { value: 'one-sided', label: 'Односторонняя' },
  { value: 'two-sided-long-edge', label: 'Двусторонняя' },
  { value: 'two-sided-short-edge', label: 'Двусторонняя (переплет сверху)' },
];

{
  /*export default function ListMenu({ onChange }: Props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const open = Boolean(anchorEl);
  const handleClickListItem = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  React.useEffect(() => {
    onChange(SIDES[selectedIndex]);
  }, [selectedIndex]);

  return (
    <div>
      <List component="nav">
        <ListItem aria-expanded={open ? 'true' : undefined} onClick={handleClickListItem}>
          <ListItemText primary={options[selectedIndex]} />
        </ListItem>
      </List>

      <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
        {options.map((option, index) => (
          <MenuItem
            key={option}
            selected={index === selectedIndex}
            onClick={(event) => handleMenuItemClick(event, index)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}*/
}

// Sides field for the PrintSettingsForm.
{
  /*export default function SidesField({ sides, onChange }: Props) {
  return (
    <FormControl component="fieldset">
      <FormLabel component="legend">
        <Typography variant="h4" color="textPrimary">
          Стороны
        </Typography>
      </FormLabel>

      <RadioGroup aria-label="Стороны" name="стороны" value={sides} onChange={onChange}>
        <FormControlLabel value="one-sided" control={<Radio />} label="Односторонняя" />
        <FormControlLabel value="two-sided-long-edge" control={<Radio />} label="Двусторонняя" />
        <FormControlLabel value="two-sided-short-edge" control={<Radio />} label="Двусторонняя (переплет сверху)" />
      </RadioGroup>
    </FormControl>
  );
}*/
}
