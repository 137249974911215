import { IconButton, Typography, useTheme } from '@mui/material';
import { MobileStyledMenu, StyledMenuItem } from './shared/StyledMenu';
import { UserContext, userContext } from 'Context';

import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import MenuIcon from '@mui/icons-material/Menu';
import React from 'react';
import { useNavigate } from 'react-router-dom';

// Menu that is displayed on the mobile version of the website
export default function MobileNavBarMenu() {
  const theme = useTheme();
  const { updateContext } = React.useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const menuClick = (address: string) => {
    setAnchorEl(null);
    navigate(address);
  };

  const Logout = () => {
    updateContext(userContext);
    document.cookie = 'token= ; expires = Thu, 01 Jan 1970 00:00:00 GMT';
  };
  return (
    <>
      <IconButton color="inherit" aria-label="open menu" component="span" onClick={handleClick} size="large">
        <MenuIcon fontSize="large" />
      </IconButton>
      <MobileStyledMenu
        id="mobile-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <StyledMenuItem onClick={() => menuClick('/')}>Печать</StyledMenuItem>
        <StyledMenuItem onClick={() => menuClick('/printers')}>Принтеры</StyledMenuItem>
        {/*<StyledMenuItem onClick={() => menuClick('/printingHouse')}>Типография</StyledMenuItem>*/}
        <StyledMenuItem onClick={() => menuClick('/account')}>Оплата</StyledMenuItem>
        <StyledMenuItem onClick={() => menuClick('/news')}>Новости</StyledMenuItem>
        <StyledMenuItem onClick={() => menuClick('/account')}>Личный кабинет</StyledMenuItem>
        <StyledMenuItem onClick={Logout}>
          <ExitToAppIcon fontSize="small" />
          <Typography sx={{ paddingLeft: theme.spacing(1) }}>Выйти</Typography>
        </StyledMenuItem>
      </MobileStyledMenu>
    </>
  );
}
