import { Box, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import React from 'react';
import logo from 'Assets/cloud(white).svg';
import mail from 'Assets/mail (4).svg';
import phone from 'Assets/phone (2).svg';
import vk from 'Assets/vk.svg';

const useStyles = makeStyles()((theme) => ({
  logo: {
    height: theme.spacing(7),
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),
  },
  smallLogo: {
    height: theme.spacing(4),
    marginLeft: theme.spacing(2.5),
  },
  footerText: { marginLeft: theme.spacing(1), marginTop: theme.spacing(1) },
  footerItem: { marginLeft: theme.spacing(2.5), whiteSpace: 'nowrap', marginTop: theme.spacing(1) },
  footerLink: {
    textDecoration: 'none',
    fontWeight: 'lighter',

    color: theme.palette.text.secondary,
    '&:hover, &:visited': { color: theme.palette.text.secondary },
  },
  header: { marginTop: theme.spacing(2.5) },
}));

export default function LandingFooter() {
  const { classes } = useStyles();
  return (
    <Box display="flex" flexDirection="column" alignItems="center" width="100%" bgcolor="text.primary">
      <Box display="flex" alignItems="center" flexDirection="row" flexWrap="wrap" className={classes.header}>
        <img src={logo} className={classes.logo} alt="Logo is missing!" />
        <Typography variant="h4" color="textSecondary">
          Физтех-Печать
        </Typography>
        <Typography variant="body1" color="textSecondary" className={classes.footerItem}>
          <a href="/payments_info_v3" className={classes.footerLink}>
            Об оплате
          </a>
        </Typography>
      </Box>
      <Typography variant="h5" color="textSecondary" className={classes.header}>
        Связаться с нами
      </Typography>
      <Box maxWidth="100%" display="flex" flexWrap="wrap" color="text.secondary">
        <a href="https://vk.com/printmipt" target="_blank" rel="noopener noreferrer" className={classes.footerLink}>
          <Box display="flex">
            <img src={vk} height="8px" className={classes.smallLogo} alt="Logo is missing!" />
            <Typography variant="body1" className={classes.footerText}>
              ВКонтакте
            </Typography>
          </Box>
        </a>
        <Box display="flex">
          <img src={phone} height="8px" className={classes.smallLogo} alt="Logo is missing!" />
          <Typography variant="body1" className={classes.footerText}>
            +7(925)263-46-13
          </Typography>
        </Box>

        <Box display="flex">
          <img src={mail} height="8px" className={classes.smallLogo} alt="Logo is missing!" />
          <Typography variant="body1" className={classes.footerText}>
            info@printmipt.ru
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
