import { TextField, Button, Box, Grid, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import UserContext from 'Context/UserContext/UserContext';
import React from 'react';

const useStyles = makeStyles()((theme) => ({
  textfield: {
    marginTop: theme.spacing(4),
  },
  submitButton: {
    width: '100%',
    height: theme.spacing(6),
    margin: `${theme.spacing(2)} 0px`,
  },
}));

export default function PaymentForm() {
  const { classes } = useStyles();

  const { _id } = React.useContext(UserContext);

  const [sum, setSum] = React.useState<string>('100');
  const [valid, setValid] = React.useState<boolean>(true);

  const handleChange = (event) => {
    const value = parseFloat(event.target.value);
    if (!value || value <= 0) setValid(false);
    else setValid(true);
    setSum(event.target.value);
  };

  const handleSubmit = (event) => {
    if (!valid) event.preventDefault();
  };

  return (
    <Box bgcolor="background.default" boxShadow={3} borderRadius="8px" display="flex" justifyContent="center">
      <Grid item xs={10}>
        <form
          method="POST"
          action="https://printmipt.server.paykeeper.ru/create/"
          autoComplete="off"
          onSubmit={handleSubmit}
        >
          <TextField
            type="text"
            error={!valid}
            helperText="Положительное число"
            name="sum"
            value={sum}
            fullWidth
            onChange={handleChange}
            variant="outlined"
            className={classes.textfield}
            label={<Typography color="textPrimary">Сумма</Typography>}
          />
          <input type="text" name="clientid" value={_id} hidden />

          <Button type="submit" variant="contained" className={classes.submitButton} color="primary">
            Перейти к оплате
          </Button>
        </form>
      </Grid>
    </Box>
  );
}
