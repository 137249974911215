/**
 * Field to select color or grayscale printing
 * @packageDocumentation
 */

import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, Typography } from '@mui/material';

import { PrintColorMode } from 'Interfaces';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()((theme) => ({
  colorGroup: {
    display: 'flex',
    flexDirection: 'row',
    marginLeft: theme.spacing(2.5),
  },
}));

type Props = {
  print_color_mode: PrintColorMode;
  onChange(event: React.ChangeEvent<HTMLInputElement>): void;
};

export default function ColorModeField({ print_color_mode, onChange }: Props) {
  const { classes } = useStyles();
  return (
    <>
      <FormControl component="fieldset">
        {/* <FormLabel component="legend">
          <Typography variant="h4" color="textPrimary">
            Печать
          </Typography>
        </FormLabel>*/}
        <RadioGroup
          aria-label="Печать"
          name="печать"
          value={print_color_mode}
          onChange={onChange}
          className={classes.colorGroup}
        >
          <FormControlLabel value="monochrome" control={<Radio />} label="Ч/Б" />
          <FormControlLabel value="color" control={<Radio />} label="Цветная" />
        </RadioGroup>
      </FormControl>
    </>
  );
}
