/**
 * Print file button.
 * @packageDocumentation
 */
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, useTheme } from '@mui/material';
import axios from 'axios';

import { UserContext } from 'Context';
import { accessCookie } from 'Utils';
import { AlertState } from 'Components';
import { PrintFormAction } from '../shared/useFormManager';
import { isLoadResponse } from 'Interfaces';

type Props = {
  errorMessage: string;
  isSubmitting: boolean;
  dispatch: React.Dispatch<PrintFormAction>;
  setAlertState: (newState: AlertState) => void;
};

export default function PrintButton({ errorMessage, isSubmitting, dispatch, setAlertState }: Props) {
  const theme = useTheme();
  const activeFile = React.useContext(UserContext).activeFile!;
  const { updateContext } = React.useContext(UserContext);
  const navigate = useNavigate();
  const printFile = async () => {
    dispatch({ type: 'start_submission' });
    const token = accessCookie('token');

    try {
      console.log(activeFile.print_settings);
      if (!activeFile.print_settings.printer_id) {
        setAlertState({ text: 'Принтер не выбран!', severity: 'warning', isOpened: true });
        dispatch({ type: 'finish_submission' });
        return;
      }
      if (errorMessage) {
        setAlertState({ text: errorMessage, severity: 'error', isOpened: true });
        dispatch({ type: 'finish_submission' });
        return;
      }
      const fileSettings = {
        system_name: activeFile.system_name,
        print_settings: activeFile.print_settings,
      };
      const response = await axios.post(
        '/api/print/printFile',
        { ...fileSettings },
        {
          headers: { authorization: 'Bearer ' + token },
        },
      );
      setAlertState({ text: 'Файл отправлен на печать', severity: 'success', isOpened: true });
      // Type Guard from programming errors
      if (!isLoadResponse(response.data)) {
        throw new Error('Error while printing: response.data should be of type Load!');
      }
      const { name, files, favouritePrinters, balance, reserved } = response.data;
      updateContext({ name, files, favouritePrinters, balance, reserved });
      // Enables print button back after delay. So the user can't accidentally submit file multiple times.
      setTimeout(() => {
        dispatch({ type: 'finish_submission', balance });
        if (document.location.pathname === '/print') navigate('/');
      }, 4000);
    } catch (error: any) {
      if (error.response) {
        // Error while printing. Update user context to show this
        if (isLoadResponse(error.response.data)) {
          const { name, files, favouritePrinters, balance } = error.response.data;
          updateContext({ name, files, favouritePrinters, balance });
          setAlertState({ text: 'Ошибка печати', severity: 'error', isOpened: true });
        } else {
          // Displaying alert message
          setAlertState({ text: error.response.data, severity: 'error', isOpened: true });
        }
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
      setTimeout(() => {
        navigate('/');
        dispatch({ type: 'finish_submission' });
      }, 2000);
    }
  };
  return (
    <Box marginLeft={theme.spacing(5)}>
      <Button
        variant="contained"
        color="primary"
        onClick={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => printFile()}
        disabled={isSubmitting}
      >
        Печать
      </Button>
    </Box>
  );
}
