/**
 * One row of the printers table
 * @packageDocumentation
 */
import React from 'react';
import { Box, TableCell } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import { PrinterData } from 'Interfaces';
import StarCell from './PrintersTableRow/StarCell';
import { AlertState, PrinterState, ColorMode, PaperState } from 'Components';
import { mdWidth } from 'Themes';

const useStyles = makeStyles()((theme) => ({
  buttonCol: {
    maxWidth: theme.spacing(4),
  },
  tableCell: {},
  [`@media (max-width: ${mdWidth}px)`]: {
    tableCell: {
      padding: `0px ${theme.spacing(1)}`,
    },
  },
}));

type Props = {
  printer: PrinterData;
  favourite: boolean;
  useIcons: boolean;
  setAlertState(newState: AlertState): void;
};

// Styled menu
export default function PrintersTableRow({ printer, favourite, useIcons, setAlertState }: Props) {
  const color = printer.print_capabilities.color;
  const { classes } = useStyles();

  return (
    <>
      <TableCell className={classes.buttonCol}>
        <StarCell isFavourite={favourite} printerId={printer.id} setAlertState={setAlertState} />
      </TableCell>
      <TableCell className={classes.tableCell}>{printer.name}</TableCell>
      <TableCell className={classes.tableCell}>{printer.location?.description}</TableCell>
      <TableCell className={classes.tableCell}>
        <ColorMode color={color} useIcons={useIcons} />
      </TableCell>
      <TableCell className={classes.tableCell} align="left">
        <Box display="flex" alignItems="center" justifyContent="flex-start">
          <PaperState paper={printer.paper} useIcons={useIcons} />
        </Box>
      </TableCell>
      <TableCell className={classes.tableCell}>
        <PrinterState status={printer.status} isStopped={printer.isStopped} useIcons={useIcons} />
      </TableCell>
    </>
  );
}
