import React from 'react';

// Type for the file upload form state
export type UploadProgressState = 'none' | 'loading' | 'processing' | 'finished';

// Custom hook to manage upload progress
export default function useUploadState() {
  const [state, setState] = React.useState<UploadProgressState>('none');
  const [progress, setProgress] = React.useState<number>(0);

  function setUploadState({ state, progress }: { state?: UploadProgressState; progress?: number }) {
    if (state) setState(state);
    if (progress) setProgress(progress);
  }

  return { uploadState: state, progress, setUploadState };
}
