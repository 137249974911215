import axios from 'axios';
import DefaultPrinterMenuItem from './DefaultPrinterMenu/DefaultPrinterMenuItem';
import React from 'react';
import { accessCookie } from 'Utils';
import { Box, Button, Menu,  Typography, useTheme } from '@mui/material';
import { makeStyles, withStyles } from 'tss-react/mui';
import { SocketContext, UserContext } from 'Context';

const useButtonStyles = makeStyles()((theme) => ({
  button: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    maxWidth: '200px',
  },
}));

type Props = {
  setAlertState(newState: any): void;
};

export default function DefaultPrinterMenu({ setAlertState }: Props) {
  const theme = useTheme();
  const { classes } = useButtonStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const { defaultPrinter, updateContext } = React.useContext(UserContext);
  const { printers } = React.useContext(SocketContext);
  const printer_name = defaultPrinter ? printers.find((printer) => printer.id === defaultPrinter)?.name : 'Не выбран';

  const rows = printers.map((printer) => ({ _id: printer.id, name: printer.name }));
  rows.unshift({ _id: '', name: 'Не выбран' });

  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const setDefaultPrinter = async (printer_id: string) => {
    try {
      const token = accessCookie('token');
      await axios.post(
        '/api/printer/setDefaultPrinter',
        { printer_id },
        {
          headers: { authorization: 'Bearer ' + token },
        },
      );
      updateContext({ defaultPrinter: printer_id });
      setAlertState({ severity: 'success', text: 'Принтер выбран', isOpened: true });
    } catch (error: any) {
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);
        // Displaying alert message
        setAlertState({ severity: 'error', text: error.response.data, isOpened: true });
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    }
    setAnchorEl(null);
  };
  return (
    <Box marginLeft={theme.spacing(2.5)} display="flex" flexDirection="column">
      <Button
        variant="outlined"
        classes={{ root: classes.button }}
        onClick={openMenu}
        style={{ textTransform: 'none' }}
      >
        <Typography variant="body1">{printer_name}</Typography>
      </Button>

      <StyledMenu
        id="printer-selection"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={closeMenu}
        elevation={0}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {rows.map((row) => (
          <DefaultPrinterMenuItem
            key={row._id}
            printer_name={row.name}
            printer_id={row._id}
            setDefaultPrinter={setDefaultPrinter}
          />
        ))}
      </StyledMenu>
    </Box>
  );
}

// HOC for some stylings.
const StyledMenu = withStyles(Menu, () => ({
  paper: {
    border: '1px solid #d3d4d5',
  },
}));
