import { Box, Button, Typography, useTheme } from '@mui/material';
import React from 'react';
import { UserContext } from 'Context';
import axios from 'axios';
import { accessCookie } from 'Utils';
import { GoogleLogin } from '@react-oauth/google';
import { OauthPayload } from 'Components/RegistrationForm';
import vk from 'Assets/vk.svg';
import yandexIcon from 'Assets/yandexIcon.svg';
import googleIcon from 'Assets/googleIcon.svg';

type Props = {
  setAlertState(newState: any): void;
};

type OauthType = 'google' | 'yandex' | 'vk';

export default function BindAccount(props: Props) {
  const { setAlertState } = props;
  const theme = useTheme();
  const { oauth, updateContext } = React.useContext(UserContext);

  // Button payload
  const [payload, setPayload] = React.useState<OauthPayload | undefined>();

  const unbindAuthId = async (type: OauthType) => {
    try {
      const token = accessCookie('token');
      await axios.post(
        '/api/auth/unbindAccountId',
        { type },
        {
          headers: { authorization: 'Bearer ' + token },
        },
      );
      if (oauth) oauth[type] = undefined;
      updateContext({ oauth });
    } catch (error) {
      console.log(error);
    }
  };

  async function bindAccountId(payload: OauthPayload) {
    try {
      const token = accessCookie('token');
      const response = await axios.post(
        '/api/auth/bindAccountId',
        { ...payload },
        {
          headers: { authorization: 'Bearer ' + token },
        },
      );

      const data = response.data;
      if (oauth) oauth[data.type] = data.oauthId;
      updateContext({ oauth });
      setAlertState({ severity: 'success', text: 'Привязка выполнена' });
    } catch (error: any) {
      if (error.response) {
        // Request made and server responded
        console.log(error.response.data);
        console.log(error.response.status);
        console.log(error.response.headers);

        // Displaying alert message
        setAlertState({ severity: 'error', text: error.response.data, isOpened: true });
      } else if (error.request) {
        // The request was made but no response was received
        console.log(error.request);
      } else {
        // Something happened in setting up the request that triggered an Error
        console.log('Error', error.message);
      }
    }
  }

  React.useEffect(() => {
    if (payload) bindAccountId(payload);
  }, [payload]);

  React.useEffect(() => {
    const authListener = (event: MessageEvent<any>) => {
      const { data } = event;
      if (data.type === 'token') {
        if (data.source === 'vk' || data.source === 'yandex') setPayload({ type: data.source, code: data.code });
      }
    };

    window.addEventListener('message', authListener);
    return () => {
      window.removeEventListener('message', authListener);
    };
  }, []);

  const frameStyle = {
    border: 0,
  };

  // Vk auth
  const params = new URLSearchParams({
    client_id: '51622810',
    redirect_uri: 'https://printmipt.ru/page/vkAuthPage',
    response_type: 'code',
  });
  const uri = 'https://oauth.vk.com/authorize?' + params.toString();
  return (
    <Box>
      <Typography>Привязка аккаунта</Typography>
      <Box display="flex" gap={theme.spacing(1)} marginY={theme.spacing(2)} flexDirection={'column'}>
        {oauth?.google ? (
          <Box display="flex" alignItems={'center'} gap={theme.spacing(1)}>
            <img src={googleIcon} style={{ height: 38, width: 38 }} alt="Google" />
            <Typography>{oauth.google}</Typography>
            <Button variant="outlined" onClick={() => unbindAuthId('google')}>
              Отвязать
            </Button>
          </Box>
        ) : (
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              setPayload({ type: 'google', credentials: credentialResponse });
            }}
            onError={() => {
              console.log('Login Failed');
            }}
            type="icon"
            shape="square"
          />
        )}
        {oauth?.yandex ? (
          <Box display="flex" alignItems={'center'} gap={theme.spacing(1)}>
            <img src={yandexIcon} style={{ height: 38, width: 38 }} alt="Yandex" />
            <Typography>{oauth.yandex}</Typography>
            <Button variant="outlined" onClick={() => unbindAuthId('yandex')}>
              Отвязать
            </Button>
          </Box>
        ) : (
          <Box maxHeight="60px" overflow="hidden">
            <iframe
              src="https://printmipt.ru/page/yandexAuthPage.html"
              title="yandex auth"
              height="60px"
              width="60px"
              style={frameStyle}
            ></iframe>
          </Box>
        )}
        {oauth?.vk ? (
          <Box display="flex" alignItems={'center'} gap={theme.spacing(1)}>
            <img src={vk} height="38px" alt="Logo is missing!" />
            <Typography>Id: {oauth.vk}</Typography>
            <Button variant="outlined" onClick={() => unbindAuthId('vk')}>
              Отвязать
            </Button>
          </Box>
        ) : (
          <Box paddingTop="7px">
            <a href="#" onClick={() => window.open(uri, 'mywindow', 'width=1100,height=500')}>
              <img src={vk} height="38px" alt="Logo is missing!" />
            </a>
          </Box>
        )}
      </Box>
    </Box>
  );
}
