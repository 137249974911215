/**
 * Simple header is displayed on top of the login form on /admin-login page.
 * @packageDocumentation
 */
import React from 'react';
import { Box, Grid, Typography, useTheme } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

import logo from 'Assets/cloud(blue).svg';
import { mdWidth } from 'Themes';

const useStyles = makeStyles()((theme) => ({
  logo: {
    height: theme.spacing(9),
    width: 'auto',
  },
}));
/**
 * Simple header is displayed on /admin-login page on top of the login form.
 * @param text - just a text to display
 */
export default function SimpleHeader({
  smallScreenText,
  largeScreenText,
}: {
  smallScreenText: string;
  largeScreenText: string;
}) {
  const { innerWidth: width } = window;
  const theme = useTheme();
  const { classes } = useStyles();
  return (
    <Box height={theme.spacing(10)}>
      <Grid container alignItems="center">
        <Grid item xs={4} md={3}>
          <img src={logo} className={classes.logo} alt="Logo in SimpleHeader element is missing!" />
        </Grid>
        <Grid item xs={8} md={9}>
          <Typography variant="h4" color="textPrimary">
            {width >= mdWidth ? largeScreenText : smallScreenText}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}
