import React from 'react';
import { PaymentForm, SimpleHeader } from 'Components';
import { Box, Grid } from '@mui/material';

export default function PaymentPage() {
  return (
    <Box
      minHeight="100vh"
      width="100vw"
      bgcolor="background.default"
      display="flex"
      alignItems="center"
      justifyContent="center"
      overflow="auto"
    >
      <Grid item xs={12} sm={6} lg={4}>
        <Grid item xs={12}>
          <SimpleHeader largeScreenText="Форма оплаты" smallScreenText="Оплата" />
        </Grid>
        <Grid item xs={12}>
          <PaymentForm />
        </Grid>
      </Grid>
    </Box>
  );
}
