import React from 'react';
import { Box, Typography, LinearProgress, CircularProgress, useTheme, Hidden } from '@mui/material';

import { UploadProgressState } from './useUploadProgress';

export default function UploadProgress({ state, progress }: { state: UploadProgressState; progress: number }) {
  const theme = useTheme();

  const displayProgressBar = window.innerWidth > 960 ? state !== 'none' : state === 'loading';
  return (
    <Box display="flex" alignItems="center">
      {displayProgressBar && (
        <Box marginLeft={`${theme.spacing(2.5)}`} display="flex">
          <Hidden mdDown>
            <Typography variant="body2" color="textPrimary">
              Загрузка
            </Typography>
          </Hidden>
          <LinearProgress
            variant="determinate"
            sx={{ width: theme.spacing(25), marginLeft: theme.spacing(0.5), marginTop: theme.spacing(1) }}
            color="secondary"
            value={progress}
          />
          <Typography sx={{ marginLeft: theme.spacing(0.5) }} variant="body2" color="textPrimary">{`${Math.round(
            progress,
          )}%`}</Typography>
        </Box>
      )}
      {state === 'processing' && (
        <Box display="flex" marginLeft={theme.spacing(2.5)} alignItems="center" minWidth="100px">
          <Typography variant="body2" color="textPrimary">
            Обработка
          </Typography>
          <Box marginLeft={theme.spacing(0.5)}>
            <CircularProgress size={20} color="secondary" />
          </Box>
        </Box>
      )}
    </Box>
  );
}
