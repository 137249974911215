import React from 'react';
import axios from 'axios';

import { accessCookie } from 'Utils';
import { isLoadResponse } from 'Interfaces';
import { UserContextType, userContext, UserContext } from './UserContext/UserContext';

export default function UserContextLoader(props: any) {
  const [user, setUser] = React.useState(userContext);
  const [loaded, setLoaded] = React.useState(false);

  /** Updates user context using shallow merge of UserContext attributes. */
  const updateContext = (newData: Partial<UserContextType>) => setUser((data) => ({ ...data, ...newData }));

  // Async loader. Runs only once.
  React.useEffect(() => {
    load();

    // Wrapper around our async loader
    async function load() {
      if (!loaded) await loadAsync();
      setLoaded(true);
    }

    // Loads user data from the server
    async function loadAsync() {
      if (loaded) return;
      const token = accessCookie('token');
      if (!token) return;
      // Trying to fetch user data from the server
      try {
        const response = await axios.post(
          '/api/auth/load',
          {},
          {
            headers: { authorization: 'Bearer ' + token },
          },
        );
        // Type Guard from programming errors
        if (!isLoadResponse(response.data)) {
          throw new Error('Error loading: response.data should be of type Load!');
        }
        const { _id, name, files, favouritePrinters, balance, reserved, news, payments, defaultPrinter, oauth } =
          response.data;
        updateContext({
          _id,
          name,
          files,
          favouritePrinters,
          balance,
          reserved,
          news,
          payments,
          defaultPrinter,
          oauth,
        });
        return;
      } catch (error) {
        console.log(error);
      }
    }
  }, [loaded]);

  return (
    <UserContext.Provider value={{ ...user, updateContext, setContext: setUser }}>
      {loaded && props.children}
    </UserContext.Provider>
  );
}
